import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ApiResponse } from "../models/api-response.model";
import { DashboardRoutes } from "../routes/dashboard-routes";

@Injectable({
    providedIn: "root",
})
export class DashboardService {

    constructor(private http: HttpClient) { }

    getMonthlySalesAnalytic(): Observable<ApiResponse> {
        let endpoint = DashboardRoutes.getMonthlySalesAnalyticURL;
        return this.http.get<ApiResponse>(endpoint).pipe(
            catchError((error) => {
                // Handle error
                console.error('Error fetching monthly sales analytics:', error);
                throw error; // Rethrow it back to the caller
            })
        );
    }

    getRevenueByCategoryAnalytic(): Observable<ApiResponse> {
        let endpoint = DashboardRoutes.getRevenueByCategoryAnalyticURL;
        return this.http.get<ApiResponse>(endpoint).pipe(
            catchError((error) => {
                // Handle error
                console.error('Error fetching revenue by category analytics:', error);
                throw error; // Rethrow it back to the caller
            })
        );
    }

    getTopSellingAnalytic(): Observable<ApiResponse> {
        let endpoint = DashboardRoutes.getTopSellingAnalyticURL;
        return this.http.get<ApiResponse>(endpoint).pipe(
            catchError((error) => {
                // Handle error
                console.error('Error fetching top selling analytics:', error);
                throw error; // Rethrow it back to the caller
            })
        );
    }

}
