import { environment } from "src/environments/environment";


const apiURL = environment.apiURL;
    

export const UserRoutes = {

    getAllUsersURL : `${apiURL}/user`,
    createUserURL : `${apiURL}/user`,
    userDetailsURL : `${apiURL}/user`,
    userUpdateURL : `${apiURL}/user`,
    userDeleteURL : `${apiURL}/user`,
}