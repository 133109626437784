<table class="table table-borderless table-centered align-middle table-nowrap mb-0">
    <thead class="text-muted table-light">
        <tr>
            <th scope="col">Order ID</th>
            <th scope="col">Customer</th>
            <th scope="col">Product</th>
            <th scope="col">Amount</th>
            <th scope="col">Vendor</th>
            <th scope="col">Status</th>
            <th scope="col">Rating</th>
        </tr>
    </thead>
    <tbody>
        @for (data of RecentSelling; track $index) {
        <tr>
            <td>
                <a href="ecommerce/order-details" class="fw-medium link-primary">{{data.id}}</a>
            </td>
            <td>
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-2">
                        <img src="{{data.image}}" alt="" class="avatar-xs rounded-circle" />
                    </div>
                    <div class="flex-grow-1">{{data.customer}}</div>
                </div>
            </td>
            <td>{{data.product}}</td>
            <td>
                <span class="text-success">${{data.amount}}</span>
            </td>
            <td>{{data.vendor}}</td>
            <td>
                <span class="badge bg-success-subtle text-success" [ngClass]=" { 'bg-warning-subtle text-warning': data.status === 'Pending', 'bg-danger-subtle text-danger': data.status === 'Unpaid'}">{{data.status}}</span>
            </td>
            <td>
                <h5 class="fs-14 fw-medium mb-0">{{data.rating}}<span class="text-muted fs-11 ms-1">({{data.average}} votes)</span></h5>
            </td>
        </tr>}<!-- end tr -->
    </tbody><!-- end tbody -->
</table><!-- end table -->