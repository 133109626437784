import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { RoleRoutes } from "../routes/role-routes";
import { ApiResponse } from "../models/api-response.model";
import { VoucherRoutes } from "../routes/voucher-routes";

const getAllVouchersURL = VoucherRoutes.getAllVouchersURL;
const generateVoucherURL = VoucherRoutes.generateVoucherURL;
const exportCsvURL = VoucherRoutes.exportCsvURL;
const batchDeleteURL = VoucherRoutes.batchDeleteURL;

@Injectable({
  providedIn: "root",
})
export class VoucherService {

  private http = inject(HttpClient);

  getAllVoucherBatches(pageNumber?:number,pageSize?:number) : Observable<any> {
    let endpoint = `${getAllVouchersURL}`;
    if(pageNumber) {
      endpoint += `?pages=${pageNumber}&limit=${pageSize}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }
  
  exportToCsv(voucherBatchId?:number) : Observable<Blob> {
    let endpoint = `${exportCsvURL}`;
    if(voucherBatchId) {
      endpoint += `?voucherBatchId=${voucherBatchId}`;
    }
    return this.http.get(endpoint, {
      responseType: 'blob'
    });
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  generateVoucherBatch(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(generateVoucherURL, data);
  }

  deleteVoucherBatch(id:any): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(batchDeleteURL +'/'+ id);
  }



}
