import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { UserManageService } from 'src/app/services/user-manage.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response.model';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { hasPermission } from 'src/app/store';

@Component({
  selector: 'app-edit-user',
  standalone: true,
  imports: [CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule
    , NgxUiLoaderModule],
  templateUrl: './edit-user.component.html',
  styleUrl: './edit-user.component.scss'
})
export class EditUserComponent {
  breadCrumbItems!: Array<{}>;
  roles: any;
  user: any;
  parentCategory: any;
  isSubmitted: boolean = false;
  userUpdateForm!: FormGroup;
  userId: any;
  fieldTextType: boolean = false;

  private toastr = inject(ToastrService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private roleService = inject(RoleService);
  private userService = inject(UserManageService);
  private ngxLoader = inject(NgxUiLoaderService);

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "User Management", link: "/user-management" },
      { label: "Edit", active: true },
    ];
    this.userId = this.route.snapshot.paramMap.get('id');
    this.getAllRoles();
    this.getUserDetails(this.userId);
    this.userUpdateForm = new FormGroup({
      firstName: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      contactNo: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl(""),
      role: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
    });

  }


  eyeOnAction() {
    this.fieldTextType = !this.fieldTextType;
  }
  hasPermissionForUser(permission: string) {
    return hasPermission(permission);
  }

  getAllRoles(pageNumber?: number) {
    this.roleService.getAllRoles(pageNumber).subscribe((response) => {
      this.roles = response.data.records;
    });
  }

  async getUserDetails(userId: any) {

    const roleDetailRes$ = this.userService.getUserDetails(userId);
    const roleDetailRes = lastValueFrom(roleDetailRes$);
    this.ngxLoader.start();
    await roleDetailRes.then((response: ApiResponse) => {
      this.user = response.data;
      // this.userStatus = response.data.status;
      this.userUpdateForm.patchValue({
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        contactNo: this.user.contactNo,
        email: this.user.email,
        password: this.user.password,
        role: this.user.role,
        status: this.user.status,
      });
      this.ngxLoader.stop();
    });

  }

  updateUser() {
    console.log(this.userUpdateForm.value);

    this.isSubmitted = true;
    this.ngxLoader.start();
    if (this.userUpdateForm.valid) {
      const data = {
        firstName: this.userUpdateForm.value.firstName,
        lastName: this.userUpdateForm.value.lastName,
        contactNo: this.userUpdateForm.value.contactNo,
        email: this.userUpdateForm.value.email,
        password: this.userUpdateForm.value.password,
        role: this.userUpdateForm.value.role,
        status: this.userUpdateForm.value.status,
      };

      // console.log(data);

      this.userService.updateUser(this.userId, data).subscribe({
        next: (res) => {
          this.isSubmitted = false;
          this.toastr.success("User updated successfully", "Success");
          this.router.navigate(['/user-management']);
        },
        error: (e) => {
          this.isSubmitted = false;
          this.ngxLoader.stop();
        },
      });
      this.ngxLoader.stop();
    }
    else {
      this.ngxLoader.stop();
      console.log("not valid");

    }
  }
}
