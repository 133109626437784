import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appIgnoreFirstCharacterMinus]',

})
export class IgnoreFirstCharacterMinusDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let filterValue = inputElement.value;

    if (filterValue.startsWith('-')) {
      filterValue = filterValue.substring(1); // remove the item
    }

    this.ngControl.control?.setValue(filterValue, { emitEvent: false })
  }



}
