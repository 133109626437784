import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse } from "../models/api-response.model";
import { ProfileRoutes } from "../routes/profile-routes";


const patchProfile = ProfileRoutes.profileUpdateUrl;
const getProfileDetail = ProfileRoutes.profileGetUrl;


@Injectable({
    providedIn: "root",
})

export class ProfileService {

    private http = inject(HttpClient);

    updateProfile(id?: any, data?: any): Observable<ApiResponse> {
        return this.http.patch<ApiResponse>(patchProfile + '/', data);
    }
    getall(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(getProfileDetail)
    }
}