<app-breadcrumbs
  title="Vouchers"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>
<!-- end row -->

<div
  class="row"
  *ngIf="!hasPermissionForVouchers('VIEW_VOUCHER')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForVouchers('VIEW_VOUCHER')">
  <div class="col-12">
    <div class="card">
      <div class="card-header custom-tr">
        <button
          *ngIf="hasPermissionForVouchers('CREATE_VOUCHER')"
          type="button"
          class="btn btn-primary waves-effect waves-light"
          data-bs-toggle="modal"
          data-bs-target="#rolecontentModal"
          data-bs-whatever="Mary"
          (click)="openAddModel(roleDataModal)"
        >
          Add Voucher Batch &nbsp;<strong>+</strong>
        </button>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-gridjs">
            <thead>
              <tr>
                <th class="sort">Prefix</th>
                <th class="sort">Start Date</th>
                <th class="sort">End Date</th>
                <th class="sort">Amount</th>
                <th class="sort">Action</th>
              </tr>
            </thead>
            <tbody>
              @for (item of vouchers; track item; let i = $index) {
              <tr>
                <td>{{ item.prefix }}</td>
                <td>{{ item.start | date : "yyyy-MM-dd" }}</td>
                <td>{{ item.end | date : "yyyy-MM-dd" }}</td>
                <td>{{ item.amount }}</td>

                <td>
                  <a
                    *ngIf="hasPermissionForVouchers('VIEW_VOUCHER')"
                    type="button"
                    class="pl-5 custom-mr-5 btn btn-sm btn-primary waves-effect waves-light"
                    (click)="exportToCsv(item.id)"
                  >
                    Export
                  </a>
                  <button
                    *ngIf="hasPermissionForVouchers('DELETE_VOUCHER')"
                    type="button"
                    class="pl-5 btn btn-sm btn-danger waves-effect waves-light"
                    (click)="deleteVoucherBatch(item.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
        <div class="row justify-content-md-between align-items-md-center">
          <div class="col-sm-12 col-md-5">
            <div
              class="dataTables_info mb-2"
              id="tickets-table_info"
              role="status"
              aria-live="polite"
            >
              {{ calculateEntryRange() }}
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end gridjs-pagination">
              <ngb-pagination
                (pageChange)="loadPage($event)"
                [collectionSize]="totalCount"
                [page]="currentPage"
                [pageSize]="pageSize"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->

<ng-template #roleDataModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="rolecontentModal">Add New Voucher Batch</h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click'); onModalClose()"
    ></button>
  </div>
  <form (ngSubmit)="createNewVoucherBatch()" [formGroup]="voucherAddForm">
    <div class="modal-body">
      <div class="mb-3">
        <label for="roleName" class="col-form-label"
          >Batch prefix <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Batch prefix"
          formControlName="prefix"
          id="prefix"
          appLimitInput
          inputValidate="text"
        />
        <div
          *ngIf="
            isSubmitted && voucherAddForm.get('prefix')?.hasError('required')
          "
        >
          <label class="text-danger">Prefix is required</label>
        </div>
      </div>

      <div class="mb-3">
        <label for="roleName" class="col-form-label"
          >Count <span class="text-danger">*</span></label
        >
        <input
          type="number"
          class="form-control"
          placeholder="Count"
          formControlName="count"
          appLimitInput
          id="count"
          appIgnoreFullsTop
          restrictedExtraSymbol
          appVolumeInput
        />
        <div
          *ngIf="
            isSubmitted && voucherAddForm.get('count')?.hasError('required')
          "
        >
          <label class="text-danger">Count is required</label>
        </div>
      </div>
      <div class="mb-3">
        <label for="amount" class="col-form-label"
          >Amount <span class="text-danger">*</span></label
        >
        <input
          placeholder="Amount"
          type="number"
          class="form-control"
          formControlName="amount"
          id="amount"
          appVolumeInput
          restrictedExtraSymbol
        />
        <div
          *ngIf="
            isSubmitted && voucherAddForm.get('amount')?.hasError('required')
          "
        >
          <label class="text-danger">Amount is required</label>
        </div>
      </div>

      <div class="mb-3">
        <!-- Input Date -->
        <label for="exampleInputdate" class="form-label"
          >Start Date <span class="text-danger">*</span></label
        >
        <input
          type="date"
          class="form-control"
          formControlName="startDate"
          id="exampleInputdate"
          [min]="minDate"
        />
        <div
          *ngIf="
            isSubmitted && voucherAddForm.get('startDate')?.hasError('required')
          "
        >
          <label class="text-danger">Start Date is required</label>
        </div>
      </div>

      <div class="mb-3">
        <!-- Input Date -->
        <label for="exampleInputdate" class="form-label"
          >End Date <span class="text-danger">*</span></label
        >
        <input
          type="date"
          class="form-control"
          formControlName="endDate"
          id="exampleInputdate"
          [min]="minDate"
        />
        <div
          *ngIf="
            isSubmitted && voucherAddForm.get('endDate')?.hasError('required')
          "
        >
          <label class="text-danger">End Date is required</label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click'); onModalClose()"
      >
        Close
      </button>
      <button type="submit" class="btn btn-primary">Add</button>
    </div>
  </form>
</ng-template>
<ngx-ui-loader></ngx-ui-loader>
