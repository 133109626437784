import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RolesComponent } from './role-permission/roles/roles.component';
import { PermissionsComponent } from './role-permission/permissions/permissions.component';
import { CategoriesComponent } from './product-management/categories/categories.component';
import { VolumesComponent } from './product-management/volumes/volumes.component';
import { AllProductsComponent } from './product-management/product/all-products/all-products.component';
import { AllVoucherCodesComponent } from './voucher-codes/all-voucher-codes/all-voucher-codes.component';
import { AddProductComponent } from './product-management/product/add-product/add-product.component';
import { EditProductComponent } from './product-management/product/edit-product/edit-product.component';
import { AllUsersComponent } from './user-management/all-users/all-users.component';
import { AddUserComponent } from './user-management/add-user/add-user.component';
import { EditUserComponent } from './user-management/edit-user/edit-user.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from '../core/interceptors/error.interceptor';
import { BlogCategoryComponent } from './blog-section/blog-category/blog-category.component';
import { BlogComponent } from '../shared/landing/job/blog/blog.component';
import { AllBlogsComponent } from './blog-section/blogs/all-blogs/all-blogs.component';
import { AddBlogComponent } from './blog-section/blogs/add-blog/add-blog.component';
import { EditBlogComponent } from './blog-section/blogs/edit-blog/edit-blog.component';
import { AllOrdersComponent } from './order/all-orders/all-orders.component';
import { ViewOrderComponent } from './order/view-order/view-order.component';
import { ProfileComponent } from './profile/profile.component';

// Component pages

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent
  },
  {
    path: "roles",
    component: RolesComponent
  },
  {
    path: "permissions",
    component: PermissionsComponent
  },
  {
    path: "user-management",
    children: [
      {
        path: "",
        component: AllUsersComponent,
      },
      {
        path: "add",
        component: AddUserComponent,
      },
      {
        path: "edit/:id",
        component: EditUserComponent,
      },
    ]
  },
  {
    path: "product",
    children: [
      {
        path: "categories",
        component: CategoriesComponent,
      },
      {
        path: "volumes",
        component: VolumesComponent,
      },
      {
        path: "",
        component: AllProductsComponent,
      },
      {
        path: "add",
        component: AddProductComponent,
      },
      {
        path: "edit/:id",
        component: EditProductComponent,
      },
    ]
  },
  {
    path: "voucher-codes",
    children: [
      {
        path: "",
        component: AllVoucherCodesComponent,
      },
    ]
  },
  {
    path: "blog",
    children: [
      {
        path: "",
        component: AllBlogsComponent,
      },
      {
        path: "add",
        component: AddBlogComponent,
      },
      {
        path: "edit/:id",
        component: EditBlogComponent,
      },
      {
        path: "categories",
        component: BlogCategoryComponent,
      },
      {
        path: "blog-posts",
        component: AllBlogsComponent,
      },
    ]
  },
  {
    path: "orders",
    component: AllOrdersComponent,
  },
  {
    path: "order/view/:id",
    component: ViewOrderComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },]
})
export class PagesRoutingModule { }
