import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbModal, NgbModalOptions, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GeneralUtilityService } from 'src/app/services/util.service';
import { ProductService } from 'src/app/services/product.service';
import { VoucherService } from 'src/app/services/voucher.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/api-response.model';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { hasPermission } from 'src/app/store';

@Component({
  selector: 'app-all-voucher-codes',
  standalone: true,
  imports: [CommonModule, NgbPagination, SharedModule, ReactiveFormsModule, DatePipe, NgxUiLoaderModule],
  templateUrl: './all-voucher-codes.component.html',
  styleUrl: './all-voucher-codes.component.scss'
})
export class AllVoucherCodesComponent {

  breadCrumbItems!: Array<{}>;
  vouchers: any;
  currentPage: number = 1;
  totalCount: number = 0;
  pageSize: number = 10;
  voucherAddForm!: FormGroup;
  isSubmitted: boolean = false;
  apiUrl = environment.apiURL;
  minDate: any;

  private generalUtilityService = inject(GeneralUtilityService);
  private voucherService = inject(VoucherService);
  private modalService = inject(NgbModal);
  private ngxLoader = inject(NgxUiLoaderService);
  private tostr = inject(ToastrService);
  modalRef: any;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Vouchers", active: true },
    ];

    this.getAllVoucherBatches(this.currentPage, this.pageSize);
    this.voucherAddForm = new FormGroup({
      prefix: new FormControl("", [Validators.required]),
      count: new FormControl("", [Validators.required]),
      startDate: new FormControl("", [Validators.required]),
      endDate: new FormControl("", [Validators.required]),
      amount: new FormControl("", [Validators.required]),
    });
    this.removeFutureDay();
  }

  removeFutureDay() {
    const today = new Date();
    this.minDate = today.toISOString().split('T')[0];
  }

  hasPermissionForVouchers(permission: string) {
    return hasPermission(permission);
  }

  getAllVoucherBatches(pageNumber?: number, pageSize?: number) {
    this.ngxLoader.start();
    this.voucherService.getAllVoucherBatches(pageNumber, pageSize).subscribe((response) => {
      this.vouchers = response.data.records;
      this.totalCount = response.data.totalRecords;
      this.ngxLoader.stop();
    });

  }

  /*  openAddModel(content: any) {
     this.modalService.open(content);
   } */

  openEditModal(content: any, id: any) {

  }

  openAddModel(content: any) {
    const modalOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
    };

    this.modalRef = this.modalService.open(content, modalOptions);
    this.modalRef.dismissed.subscribe(((reason: any) => {
      this.isSubmitted = false;
      this.voucherAddForm.reset();
    }))
  }





  deleteVoucherBatch(batchId: any) {
    Swal.fire({
      title: "Oops...",
      text: "Are you sure you want to delete this voucher batch?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(13, 109, 164)",
      cancelButtonColor: "rgb(243, 78, 78)",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.voucherService.deleteVoucherBatch(batchId).subscribe({
          next: (response: ApiResponse) => {
            Swal.fire({
              title: "Success",
              text: "Voucher batch deleted successfully",
              confirmButtonColor: "rgb(3, 142, 220)",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                this.getAllVoucherBatches();
              }
            });
          },
          error: (e) => {
            // this.getAllFiles(this.parent_id, this.category_name);
          },
        });
      }
    });
  }

  loadPage(pageNumber: number) {
    this.getAllVoucherBatches(pageNumber, this.pageSize);
  }

  calculateEntryRange(): string {
    return this.generalUtilityService.getTblPaginationEntryRange(
      this.currentPage,
      this.pageSize,
      this.totalCount
    );
  }

  onModalClose() {
    this.isSubmitted = false;
    this.voucherAddForm.reset();
  }


  exportToCsv(id: any) {
    this.voucherService.exportToCsv(id).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'voucher-codes.csv'; // Specify filename here with appropriate extension
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    });
  }

  createNewVoucherBatch() {
    this.isSubmitted = true;

    const startDateFormatted = moment(this.voucherAddForm.value.startDate);
    const endDateFormatted = moment(this.voucherAddForm.value.endDate);
    if (this.voucherAddForm.valid) {
      this.ngxLoader.start();
      const voucherGeneratePayload = {
        prefix: this.voucherAddForm.value.prefix,
        count: this.voucherAddForm.value.count,
        amount: this.voucherAddForm.value.amount,
        start: startDateFormatted.toISOString(),
        end: endDateFormatted.toISOString(),
      };

      console.log(voucherGeneratePayload);

      this.voucherService.generateVoucherBatch(voucherGeneratePayload).subscribe({
        next: (res: any) => {
          this.isSubmitted = false;
          this.voucherAddForm.reset();

          this.tostr.success('Voucher save successfully', 'Success');
          this.modalService.dismissAll("close");
          this.getAllVoucherBatches();
          this.ngxLoader.stop();
        },
        error: (e: any) => {
          this.isSubmitted = false;
          this.ngxLoader.stop();
        },
      });
    }

  }

}
