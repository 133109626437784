import { Component, inject, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { SharedModule } from "src/app/shared/shared.module";
import { ProfileService } from "src/app/services/profile.service";
import { lastValueFrom } from "rxjs";
import { ApiResponse } from "src/app/models/api-response.model";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-profile",
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
  ],
  templateUrl: "./profile.component.html",
  styleUrl: "./profile.component.scss",
})
export class ProfileComponent implements OnInit {
  private formBuilder = inject(FormBuilder);
  private profileService = inject(ProfileService);
  private toastr = inject(ToastrService);
  private ngxLoader = inject(NgxUiLoaderService);

  profileForm!: FormGroup;
  passwordForm!: FormGroup;
  isSubmitted: boolean = false;
  userData: any;
  userId: string | any;
  notSameError: boolean = false;
  fieldTextType: boolean = false;

  ngOnInit(): void {
    this.initProfile();
    this.getProfileDetail();
  }

  initProfile() {
    this.profileForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      contactNumber: ["", Validators.required],
      address: [""],
      email: [""],
    });

    this.passwordForm = this.formBuilder.group({
      oldPassword: ["", Validators.required],
      newPassword: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
  }

  getProfileDetail() {
    this.ngxLoader.start();
    this.profileService.getall().subscribe((res: any) => {
      //console.log('profile Full dataks', res);
      this.userData = res?.data;
      this.userId = res?.data?.id;
      this.patchValue();
      this.ngxLoader.stop();
    });
  }

  patchValue() {
    this.profileForm.patchValue({
      firstName: this.userData.firstName,
      lastName: this.userData.lastName,
      contactNumber: this.userData.contactNo,
      address: this.userData.address,
      email: this.userData.email,
    });
  }


  isActivePasswordRetype: boolean = false;

  passwordMismatch: any;

  onSubmit() {
    this.isSubmitted = true;
    if (this.profileForm.valid) {
      let profilePayload = {};
      profilePayload = {
        firstName: this.profileForm.value.firstName,
        lastName: this.profileForm.value.lastName,
        contactNo: this.profileForm.value.contactNumber,
        address: this.profileForm.value.address,
      };

      this.ngxLoader.start();
      this.profileService
        .updateProfile(this.userId, profilePayload)
        .subscribe({
          next: (res) => {
            this.isSubmitted = false;
            this.ngxLoader.stop();
            this.toastr.success("Personal Details updated successfully", "Success!");
          },
          error: (error: HttpErrorResponse) => {
            this.isSubmitted = false;
            this.ngxLoader.stop();
            this.toastr.error(
              error.message ||
              "Something went wrong, Please try again later.",
              "Error!"
            );
          },
        });
    }
  }

  eyeOnAction() {
    this.fieldTextType = !this.fieldTextType;
  }

  fieldTextTypeTwo: boolean = false;
  eyeOnActionTwo() {
    this.fieldTextTypeTwo = !this.fieldTextTypeTwo;
  }
  fieldTextTypeThree: boolean = false;

  eyeOnActionThree() {
    this.fieldTextTypeThree = !this.fieldTextTypeThree;
  }
  changePassword() {
    this.isSubmitted = true;
    if (this.passwordForm.valid) {
      let passwordVal = this.passwordForm.get("newPassword")?.value;
      let confirmPasswordVal = this.passwordForm.get("confirmPassword")?.value;

      if (passwordVal == confirmPasswordVal) {
        this.notSameError = false;
        let passwordPayload = {
          password: this.passwordForm.value.newPassword,
          oldPassword: this.passwordForm.value.oldPassword,
        };
        this.ngxLoader.start();
        this.profileService
          .updateProfile(this.userId, passwordPayload)
          .subscribe({
            next: (res) => {
              this.isSubmitted = false;
              this.ngxLoader.stop();
              this.toastr.success("Password updated successfully", "Success!");
            },
            error: (error: HttpErrorResponse) => {
              this.isSubmitted = false;
              this.ngxLoader.stop();
              this.toastr.error(
                error.message ||
                "Something went wrong, Please try again later.",
                "Error!"
              );
            },
          });
      } else {
        this.notSameError = true;
      }
    }
  }
}
