import { Component, ElementRef, ViewChild, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { NgbModal, NgbModalOptions, NgbPagination } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/shared/shared.module";
import { GeneralUtilityService } from "src/app/services/util.service";
import { ProductService } from "src/app/services/product.service";
import { lastValueFrom } from "rxjs";
import { ApiResponse } from "src/app/models/api-response.model";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { hasPermission } from "src/app/store";
import { ImageCropperComponent } from "ngx-image-cropper";




interface SelectedFile {
  file: File;
  url: string;
}

interface Images {
  id: File;
  path: string;
}

@Component({
  selector: "app-categories",
  standalone: true,
  imports: [
    CommonModule,
    NgbPagination,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgxUiLoaderModule,
    ImageCropperComponent,
  ],
  templateUrl: "./categories.component.html",
  styleUrl: "./categories.component.scss",
})
export class CategoriesComponent {
  orderTypes = [
    { id: 1, name: "Order", value: "order" },
    { id: 2, name: "Inquiry", value: "inquiry" },
  ];
  cropperAspectRatio: number = 1 / 1;
  categories: any;
  images: Images[] = [];
  category: any;
  parentCategory: any;
  // parentCategory: any;
  breadCrumbItems!: Array<{}>;
  currentPage: number = 1;
  totalCount: number = 0;
  pageSize: number = 10;
  categoryAddForm!: FormGroup;
  categoryUpdateForm!: FormGroup;
  isSubmitted: boolean = false;
  imageChangedEvent: any = "";
  selectedFiles: SelectedFile[] = [];
  croppedImage: any;
  selectedType: any = this.orderTypes[0];
  selectedImage: any;
  isClickImage: boolean = true;
  isImageUploaded = false;

  activeStatus = [{ name: 'All', value: -1 }, { name: 'Active', value: 1 }, { name: 'Deactive', value: 0 }];
  selectedStatus = this.activeStatus[0].value;
  anotherSelected: any = 'All'

  isUpdateStatus = [{ name: 'Activated', value: 1 }, { name: 'Deactivated', value: 0 }]

  @ViewChild("categoryUpdateModal") editDataModal: any;
  @ViewChild('fileInputAdd') fileInputAdd!: ElementRef;

  private modalService = inject(NgbModal);
  private generalUtilityService = inject(GeneralUtilityService);
  private productService = inject(ProductService);
  private toastr = inject(ToastrService);
  private ngxLoader = inject(NgxUiLoaderService);
  modalRef: any;
  fileErrorDisplay: boolean = false;
  trueOrFalse: boolean = true;
  fileErrorDisplayForUpdate: boolean = false;
  isClearImage: any;
  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Product Categories", active: true },
    ];

    //this.getAllCategories();
    this.getAllCategories(this.currentPage, this.pageSize, this.selectedStatus);
    this.categoryAddForm = new FormGroup({
      categoryName: new FormControl("", [Validators.required]),
      orderType: new FormControl("", [Validators.required]),
      parentId: new FormControl(""),
      categoryImage: new FormControl(null, [Validators.required])

    });
    this.categoryUpdateForm = new FormGroup({
      categoryName: new FormControl("", [Validators.required]),
      orderType: new FormControl(""),
      parentId: new FormControl(""),
      statusCategory: new FormControl("")
    });
  }

  hasPermissionForCategory(permission: string) {
    return hasPermission(permission);
  }

  changeTheSelectedStatus(event: any) {
    const isSelected = event?.value;
    this.selectedStatus = isSelected;
    //console.log('this.eeeeeee', this.selectedStatus);
    this.getAllCategories(this.currentPage, this.pageSize, this.selectedStatus);

  }

  fileChangeEvent(event: any): void {

    console.log("file input triggers:", this.fileInputAdd);

    this.isClickImage = false;
    //console.log('Hey', this.isClickImage);
    this.isImageUploaded = false;

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0];
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!allowedTypes.includes(file.type)) {
        this.fileErrorDisplay = true;
        this.fileErrorDisplayForUpdate = true;
        event.target.value = '';
        this.trueOrFalse = true;
        return;
      }
      this.trueOrFalse = false;
      this.fileErrorDisplay = false;
      this.fileErrorDisplayForUpdate = false;
      this.imageChangedEvent = event;
      this.setAspectRatio(event);
    }
  }

  setAspectRatio(event: any) {
    console.log("event: ", event.target.files);
    const img = new Image();
    img.onload = () => {
      this.cropperAspectRatio = img.width / img.height;
    };
    img.src = event.target.files[0].src;
  }

  imageCropped(event: any) {
    this.croppedImage = event.blob;
  }
  imageLoaded(event: any) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  addCroppedImage() {
    if (this.croppedImage) {
      const croppedFile = new File(
        [this.croppedImage],
        new Date().toISOString().toString() + "_cropped_image.png",
        { type: "image/png" }
      );
      const fileURL = URL.createObjectURL(croppedFile);
      this.selectedFiles.push({ file: croppedFile, url: fileURL });
      this.removeCroppedImageEvent();

    }
  }

  dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  removeCroppedImage(index: number) {
    this.selectedFiles.splice(index, 1);
  }

  /* removeCroppedImageEvent() {
    console.log('remove click');
    this.imageChangedEvent = null;
    this.croppedImage = null;
    this.fileInput.nativeElement.value = '';

  } */
  removeCroppedImageEvent(): void {

    console.log('This file input :', this.editDataModal);
    this.imageChangedEvent = null;
    this.croppedImage = null;
    // this.fileInputAdd.nativeElement.value = null;

    // this.categoryAddForm.categoryImage

  }

  getAllCategories(pageNumber?: number, pageSize?: number, selectedStatus?: any) {
    this.ngxLoader.start();
    this.productService
      .getAllCategories(pageNumber, pageSize, selectedStatus)
      .subscribe((response) => {
        this.categories = response.data.records;
        this.totalCount = response.data.totalRecords;
      });
    this.ngxLoader.stop();
  }

  openRoleModal(content: any) {
    // this.modalService.open(content);
    this.fileErrorDisplay = false;
    this.fileErrorDisplayForUpdate = false;
    const modalOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
    };

    this.modalRef = this.modalService.open(content, modalOptions);
    this.modalRef.dismissed.subscribe(((reason: any) => {
      this.isSubmitted = false;
      this.isImageUploaded = false;
      this.categoryAddForm.reset();
      this.fileErrorDisplay = false;

    }))
  }

  /*  */
  onModalClose() {
    this.isSubmitted = false;
  }

  openEditModal(content: any, roleId: number) {
    this.isClickImage = true;
    this.getCategoryDetails(content, roleId);
    this.fileErrorDisplayForUpdate = false;
  }

  async getCategoryDetails(content: any, roleId: any) {
    //console.log('content =----------', content);

    this.ngxLoader.start();
    const roleDetailRes$ = this.productService.getCategoryDetails(roleId);
    const roleDetailRes = lastValueFrom(roleDetailRes$);
    await roleDetailRes.then((response: ApiResponse) => {
      this.category = response.data;
      this.parentCategory = response.data.parentId;

      //this.images = [];
      response.data.files.forEach((file: any) => { // original 
        console.log('this Images part 2', file);
        this.images.push({ id: file?.file?.id, path: file?.file?.path });
        this.selectedImage = file?.file?.path;
      });
      //this.selectedImage = this.images;

      //  console.log('This Selected Image :', this.selectedImage);


      //this.images = [];
      //  this.selectedImage = this.images.length > 0 ? this.images[0]?.path : null

      // console.log("order Type", this.category.type);

      this.selectedType = this.orderTypes.find(type => type.value === this.category.type);
      this.categoryUpdateForm.patchValue({
        categoryName: this.category.name,
        orderType: this.selectedType,
        //images: this.images.length > 0 ? this.images[0]?.path : null
        statusCategory: this.isUpdateStatus.find(status => status.value === this.category.status)
      });

      this.modalService.open(content);
    });
    this.ngxLoader.stop();
  }

  deleteRole(catId: number) {
    Swal.fire({
      title: "Oops...",
      text: "Are you sure you want to delete this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(13, 109, 164)",
      cancelButtonColor: "rgb(243, 78, 78)",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.productService.deleteCategory(catId).subscribe({
          next: (response: ApiResponse) => {
            Swal.fire({
              title: "Success",
              text: "Category deleted successfully",
              confirmButtonColor: "rgb(3, 142, 220)",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                //  this.getAllCategories();
                this.getAllCategories(this.currentPage, this.pageSize, this.selectedStatus);
              }
            });
          },
          error: (e) => {
            // this.getAllFiles(this.parent_id, this.category_name);
          },
        });
      }
    });
  }

  calculateEntryRange(): string {
    return this.generalUtilityService.getTblPaginationEntryRange(
      this.currentPage,
      this.pageSize,
      this.totalCount
    );
  }

  loadPage(pageNumber: number) {
    this.getAllCategories(pageNumber, this.pageSize, this.selectedStatus);
  }

  createNewCategory() {
    // console.log(this.parentCategory);
    let parentId = null;
    if (this.parentCategory) {
      parentId = this.parentCategory.id;
    }
    this.isSubmitted = true;
    this.isImageUploaded = this.trueOrFalse;

    if (this.categoryAddForm.valid) {
      this.ngxLoader.start();
      const formData = new FormData();

      for (const element of this.selectedFiles) {
        formData.append("files", element.file);
      }

      const fileUploadRes$ = this.generalUtilityService.fileUpload(formData);
      const fileUploadRes = lastValueFrom(fileUploadRes$);

      fileUploadRes.then((response: ApiResponse) => {
        if (response.data) {
          this.images = response.data.map((item: any) => ({ id: item.id }));
          console.log(response.data);

          const data = {
            name: this.categoryAddForm.value.categoryName,
            parentId: null,
            files: this.images,
            type: this.selectedType.value
          };

          const productCreateRes$ = this.productService.createCategory(data);
          const productCreateRes = lastValueFrom(productCreateRes$);

          productCreateRes.then(
            (response: ApiResponse) => {
              this.isSubmitted = false;
              this.categoryAddForm.reset();
              this.modalService.dismissAll("close");
              this.toastr.success("Category save successfully", "Success");
              this.getAllCategories(this.currentPage, this.pageSize, this.selectedStatus);
              // this.getAllCategories();
              this.ngxLoader.stop();
            },
            (error) => {
              this.isSubmitted = false;
              this.ngxLoader.stop();
            }
          );
        }
      });
    }
    // this.ngxLoader.stop();
  }

  updateCategory() {
    this.isSubmitted = true;

    //console.log('this is image length', this.images.length);
    //console.log('this is selected File length', this.selectedFiles.length);
    //console.log('updated Data--------');
    if (this.selectedFiles.length > 0) {
      //this.ngxLoader.start();

      if (this.categoryUpdateForm.valid) {
        this.ngxLoader.start();
        const formData = new FormData();

        for (const element of this.selectedFiles) {
          formData.append("files", element.file);
        }
        // this.ngxLoader.start();
        const fileUploadRes$ = this.generalUtilityService.fileUpload(formData);
        const fileUploadRes = lastValueFrom(fileUploadRes$);
        //this.ngxLoader.start();
        fileUploadRes.then((response: ApiResponse) => {
          if (response.data) {
            this.images = response.data.map((item: any) => ({ id: item.id }));
            console.log(this.categoryUpdateForm.value);
            //console.log('Is Responce:', response.data);

            const data = {
              name: this.categoryUpdateForm.value.categoryName,
              parentId: null,
              files: this.images,
              type: this.selectedType.value,
              status: this.categoryUpdateForm.value?.isUpdateStatus?.value
            };


            const productUpdateRes$ = this.productService.updateCategory(
              this.category.id,
              data
            );
            const productUpdateRes = lastValueFrom(productUpdateRes$);
            console.log('updated Data', productUpdateRes$);
            productUpdateRes.then(
              (response: ApiResponse) => {
                this.isSubmitted = false;
                this.toastr.success(
                  "Category updated successfully",
                  "Success"
                );
                this.categoryUpdateForm.reset();
                this.modalService.dismissAll("close");
                //this.getAllCategories();
                this.getAllCategories(this.currentPage, this.pageSize, this.selectedStatus);
                this.ngxLoader.stop();
              },
              (error) => {
                this.isSubmitted = false;
                this.ngxLoader.stop();
              }
            );
          }
        });
      }
    }
    else {
      // console.log('this is else Part');

      if (this.categoryUpdateForm.valid) {
        this.ngxLoader.start()
        const data = {
          name: this.categoryUpdateForm.value.categoryName,
          parentId: null,
          type: this.selectedType.value,
          status: this.categoryUpdateForm.value?.statusCategory?.value
        };
        console.log('The Data :', data);


        this.productService.updateCategory(this.category.id, data).subscribe({
          next: (res: any) => {
            this.isSubmitted = false;
            this.toastr.success("Category updated successfully", "Success");
            this.categoryUpdateForm.reset();
            this.modalService.dismissAll("close");
            //this.getAllCategories();
            this.getAllCategories(this.currentPage, this.pageSize, this.selectedStatus);
            this.ngxLoader.stop();
          },
          error: (e: any) => {
            this.isSubmitted = false;
            this.ngxLoader.stop();
          },
        });
      }
    }
    //this.ngxLoader.stop();
  }

  modalClose() {
    this.categoryAddForm.reset();
    this.categoryUpdateForm.reset();
    this.modalService.dismissAll("close");
  }
}

