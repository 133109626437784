import { environment } from "src/environments/environment";


const apiURL = environment.apiURL;
    

export const BlogRoutes = {

    getAllBlogCategoriesURL : `${apiURL}/admin/blog-category`,
    categoryDetailsURL : `${apiURL}/admin/blog-category`,
    categoryDeleteURL : `${apiURL}/admin/blog-category`,
    createCategoryURL : `${apiURL}/admin/blog-category`,
    categoryUpdateURL : `${apiURL}/admin/blog-category`,

    getAllBlogPostsURL : `${apiURL}/admin/blogs`,
    createBlogPostURL : `${apiURL}/admin/blogs`,
    blogPostDetailsURL : `${apiURL}/admin/blogs`,
    blogPostUpdateURL : `${apiURL}/admin/blogs`,
    blogPostDeleteURL : `${apiURL}/admin/blogs`,
}