import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { RoleRoutes } from "../routes/role-routes";
import { ApiResponse } from "../models/api-response.model";

const getAllRolesURL = RoleRoutes.getAllRolesURL;
const createRoleURL = RoleRoutes.createRoleURL;
const roleDetailsURL = RoleRoutes.roleDetailsURL;
const roleUpdateURL = RoleRoutes.roleUpdateURL;
const roleDeleteURL = RoleRoutes.roleDeleteURL;

@Injectable({
  providedIn: "root",
})
export class RoleService {

  private http = inject(HttpClient);

  getAllRoles(pageNumber?: number, pageSize?: number, status?: number, name?: any): Observable<any> {
    let endpoint = `${getAllRolesURL}`;
    if (pageNumber) {
      endpoint += `?pages=${pageNumber}&limit=${pageSize}&status=${status}&name=${name}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }

  createRole(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(createRoleURL, data);
  }

  getRoleDetails(role_id: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${roleDetailsURL}/${role_id}`);
  }

  updateRole(id: any, data: any): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(roleUpdateURL + '/' + id, data);
  }

  deleteRole(id: any): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(roleDeleteURL + '/' + id);
  }

}
