import { Injectable, inject } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, from, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "../services/auth.service";
import { TokenStorageService } from "../services/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class ErrorInterceptor implements HttpInterceptor {
  public router = inject(Router);
  private toastr = inject(ToastrService);
  private authService = inject(AuthenticationService);
  private tokenStorageService = inject(TokenStorageService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        const error =
          err.error?.message?.message?.errors?.name ||
          err.error?.message.message ||
          err.error?.message ||
          err.message.message ||
          err.statusText ||
          err.error ||
          err;
          // const refreshToken = this.tokenStorageService.getRefreshToken();
        if (500 <= err.status && err.status <= 599) {
          this.toastr.error(error, "Error!");
        } else if (300 <= err.status && err.status <= 499) {
          this.toastr.warning(error, "Warning!");
        } else if (200 <= err.status && err.status <= 299) {
          this.toastr.success(error, "Success!");
        } else {
          this.toastr.error(error, "Error!");
        }

        return throwError(() => error);
      })
    );
  }

  redirectToUnauthorized() {
    this.router.navigate(["/unauthorize"]);
  }


}
