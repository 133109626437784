import { environment } from "src/environments/environment";


const apiURL = environment.apiURL;
    

export const VoucherRoutes = {

    getAllVouchersURL : `${apiURL}/admin/voucher-batch/find-all-voucher-batches`,
    generateVoucherURL : `${apiURL}/admin/voucher-batch`,
    exportCsvURL : `${apiURL}/admin/voucher/export`,
    batchDeleteURL : `${apiURL}/admin/voucher-batch`,
}