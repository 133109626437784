import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'Dashboard',
    icon: 'home',
    link: '/',
  },
  {
    id: 9,
    label: 'Product Management',
    icon: 'archive',
    permissions: ['VIEW_PRODUCT'],
    subItems: [
      {
        id: 10,
        label: 'Categories',
        link: '/product/categories',
        parentId: 9,
      },
      {
        id: 11,
        label: 'Volumes',
        link: '/product/volumes',
        parentId: 9
      },
      {
        id: 12,
        label: 'Products',
        link: '/product',
        parentId: 9
      },
    ]
  },
  {
    id: 3,
    label: 'Orders',
    icon: 'shopping-cart',
    link: '/orders',
    permissions: ['VIEW_ORDER'],
  },
  {
    id: 3,
    label: 'Role Permissions',
    icon: 'key',
    permissions: ['ASSIGN_ROLE_PERMISSION'],
    subItems: [
      {
        id: 4,
        label: 'Roles',
        link: '/roles',
        parentId: 3,
      },
      {
        id: 5,
        label: 'Permissions',
        link: '/permissions',
        parentId: 3
      },
    ]
  },
  {
    id: 6,
    label: 'User Management',
    icon: 'user',
    roles: ['admin'],
    permissions: ['VIEW_USER'],
    subItems: [
      {
        id: 7,
        label: 'Add New User',
        link: '/user-management/add',
        parentId: 6,
      },
      {
        id: 8,
        label: 'All Users',
        link: '/user-management',
        parentId: 6
      },
    ]
  },
  /*   {
      id: 9,
      label: 'Product Management',
      icon: 'archive',
      permissions: ['VIEW_PRODUCT'],
      subItems: [
        {
          id: 10,
          label: 'Categories',
          link: '/product/categories',
          parentId: 9,
        },
        {
          id: 11,
          label: 'Volumes',
          link: '/product/volumes',
          parentId: 9
        },
        {
          id: 12,
          label: 'Products',
          link: '/product',
          parentId: 9
        },
      ]
    }, */
  /*   {
     id: 3,
     label: 'Orders',
     icon: 'gift',
     link: '/orders',
     permissions: ['VIEW_ORDER'],
   }, */
  {
    id: 13,
    label: 'Voucher Codes',
    link: '/voucher-codes',
    icon: 'tag',
    permissions: ['VIEW_VOUCHER'],
  },
  {
    id: 3,
    label: 'Blog',
    icon: 'clipboard',
    permissions: ['VIEW_BLOG_POST'],
    subItems: [
      {
        id: 4,
        label: 'Blog Categories',
        link: '/blog/categories',
        parentId: 3,
      },
      {
        id: 5,
        label: 'Blog Posts',
        link: '/blog',
        parentId: 3
      },
    ]
  },
];
