import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appLimitInput]'
})
export class MaxInputDirective {

  private maxLength = 155;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    if (input.value.length > this.maxLength) {
      input.value = input.value.slice(0, this.maxLength);
    }
  }
}

