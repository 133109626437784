import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { OrderService } from 'src/app/services/order.service';
import { ToastrService } from 'ngx-toastr';
import { GeneralUtilityService } from 'src/app/services/util.service';
import { hasPermission } from 'src/app/store';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-all-orders',
  standalone: true,
  imports: [CommonModule, NgbPagination, SharedModule, RouterModule, NgxUiLoaderModule, NgSelectModule, FormsModule],
  templateUrl: './all-orders.component.html',
  styleUrl: './all-orders.component.scss'
})
export class AllOrdersComponent {

  orders: any;
  order: any;
  breadCrumbItems!: Array<{}>;
  currentPage: number = 1;
  totalCount: number = 0;
  pageSize: number = 10;
  defaultSelect: any = 'All'
  orderStatus: any = [{ name: 'All', value: '' }, { name: 'Pending', value: 'Pending' }, { name: 'On Progress', value: 'On Progress' }, { name: 'Delivered', value: 'Delivered' }, { name: 'Cancelled', value: 'Cancelled' }];

  selectedOrderStatus: any = this.orderStatus[0].value;
  searchValue: any = '';
  debounceFilter: any = '';


  private ngxLoader = inject(NgxUiLoaderService);
  private orderService = inject(OrderService);
  private toastr = inject(ToastrService);
  private generalUtilityService = inject(GeneralUtilityService);

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Orders", active: true },
    ];

    this.getAllOrders(this.currentPage, this.pageSize, this.selectedOrderStatus, this.searchValue);
  }

  hasPermissionForOrders(permission: string) {
    return hasPermission(permission);
  }


  onChangeBlogStatus(event: any) {
    this.selectedOrderStatus = event.value;
    this.getAllOrders(this.currentPage, this.pageSize, this.selectedOrderStatus, this.searchValue);
  }


  searchOnChangeBlog(event: any) {
    clearTimeout(this.debounceFilter);
    this.debounceFilter = setTimeout(() => {
      this.searchValue = event;
      console.log(this.searchValue);

      this.getAllOrders(this.currentPage, this.pageSize, this.selectedOrderStatus, this.searchValue);
    }, 1000);
  }

  getAllOrders(pageNumber?: number, pageSize?: number, selectedOrderStatus?: any, searchValue?: any) {
    let trimValue = searchValue.replace(/^\s+|\s+$/gm, '');
    this.ngxLoader.start();
    this.orderService.getAllOrders(pageNumber, pageSize, selectedOrderStatus, trimValue).subscribe((response) => {
      this.orders = response.data.records;
      this.totalCount = response.data.totalRecords;
      console.log("Orders :", response.data.records);
      this.ngxLoader.stop();
    });

  }

  calculateEntryRange(): string {
    return this.generalUtilityService.getTblPaginationEntryRange(
      this.currentPage,
      this.pageSize,
      this.totalCount
    );
  }

  loadPage(pageNumber: number) {
    this.getAllOrders(pageNumber, this.pageSize, this.selectedOrderStatus, this.searchValue);
    //this.getAllOrders(this.currentPage, );
  }
}
