import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { RoleRoutes } from "../routes/role-routes";
import { ApiResponse } from "../models/api-response.model";
import { UserRoutes } from "../routes/user-routes";

const getAllUsersURL = UserRoutes.getAllUsersURL;
const createUserURL = UserRoutes.createUserURL;
const userDetailsURL = UserRoutes.userDetailsURL;
const userUpdateURL = UserRoutes.userUpdateURL;
const userDeleteURL = UserRoutes.userDeleteURL;

@Injectable({
  providedIn: "root",
})
export class UserManageService {

  private http = inject(HttpClient);

  getAllUsersURL(pageNumber?: number, pageSize?: number, selectedStatus?: number, searchFirstName?: string, searchContact?: string): Observable<any> {
    let endpoint = `${getAllUsersURL}`;
    console.log('page Number', pageNumber);

    if (pageNumber) {
      endpoint += `?pages=${pageNumber}&limit=${pageSize}&status=${selectedStatus}&name=${searchFirstName}&contactNo=${searchContact}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }

  createUser(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(createUserURL, data);
  }

  getUserDetails(role_id: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${userDetailsURL}/${role_id}`);
  }

  updateUser(id: any, data: any): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(userUpdateURL + '/' + id, data);
  }

  deleteUser(id: any): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(userDeleteURL + '/' + id);
  }

}
