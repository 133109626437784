import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appIgnoreFullsTop]',

})
export class IgnoreFullsTopDirective {

  constructor() { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void { // this used for when type=number
    if (event.key === '.') {
      event.preventDefault();
    }
  }

}
