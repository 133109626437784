import { environment } from "src/environments/environment";


const apiURL = environment.apiURL;
    

export const ProductRoutes = {

    //categories routes
    getAllCategoriesURL : `${apiURL}/admin/category/find-all-category`,
    createCategoryURL : `${apiURL}/admin/category`,
    categoryDetailsURL : `${apiURL}/admin/category/find-one-category`,
    categoryUpdateURL : `${apiURL}/admin/category`,
    categoryDeleteURL : `${apiURL}/admin/category`,

    //volume routes
    getAllVolumesURL : `${apiURL}/admin/volume/find-all-volume`,
    createVolumeURL : `${apiURL}/admin/volume`,
    volumeDetailsURL : `${apiURL}/admin/volume/find-one-volume`,
    volumeUpdateURL : `${apiURL}/admin/volume`,
    volumeDeleteURL : `${apiURL}/admin/volume`,

    // product
    createProductURL : `${apiURL}/admin/product`,
    getAllProductsURL : `${apiURL}/admin/product/get-all-products`,
    deleteProductURL : `${apiURL}/admin/product`,
    productDetailsURL : `${apiURL}/admin/product`,
    productUpdateURL : `${apiURL}/admin/product`
}