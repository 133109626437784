import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { RoleRoutes } from "../routes/role-routes";
import { ApiResponse } from "../models/api-response.model";
import { ProductRoutes } from "../routes/product-routes";

const getAllCategoriesURL = ProductRoutes.getAllCategoriesURL;
const createCategoryURL = ProductRoutes.createCategoryURL;
const categoryDetailsURL = ProductRoutes.categoryDetailsURL;
const categoryUpdateURL = ProductRoutes.categoryUpdateURL;
const categoryDeleteURL = ProductRoutes.categoryDeleteURL;

const getAllVolumesURL = ProductRoutes.getAllVolumesURL;
const createVolumeURL = ProductRoutes.createVolumeURL;
const volumeDetailsURL = ProductRoutes.volumeDetailsURL;
const volumeUpdateURL = ProductRoutes.volumeUpdateURL;
const volumeDeleteURL = ProductRoutes.volumeDeleteURL;

const createProductURL = ProductRoutes.createProductURL;
const getAllProductsURL = ProductRoutes.getAllProductsURL;
const deleteProductURL = ProductRoutes.deleteProductURL;
const productDetailsURL = ProductRoutes.productDetailsURL;
const productUpdateURL = ProductRoutes.productUpdateURL;

@Injectable({
  providedIn: "root",
})
export class ProductService {

  private http = inject(HttpClient);

  //category service
  getAllCategories(pageNumber?: number, pageSize?: number, selectedStatus?: any): Observable<any> {
    let endpoint = `${getAllCategoriesURL}`;
    if (pageNumber) {
      endpoint += `?pages=${pageNumber}&limit=${pageSize}&status=${selectedStatus}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }

  createCategory(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(createCategoryURL, data);
  }

  getCategoryDetails(category_id: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${categoryDetailsURL}/${category_id}`);
  }

  updateCategory(id: any, data: any): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(categoryUpdateURL + '/' + id, data);
  }

  deleteCategory(id: any): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(categoryDeleteURL + '/' + id);
  }

  //volume service
  getAllVolumes(pageNumber?: number, pageSize?: number, status?: number): Observable<any> {
    let endpoint = `${getAllVolumesURL}`;
    if (pageNumber) {
      endpoint += `?pages=${pageNumber}&limit=${pageSize}&status=${status}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }

  createVolume(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(createVolumeURL, data);
  }

  getVolumeDetails(category_id: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${volumeDetailsURL}/${category_id}`);
  }

  updateVolume(id: any, data: any): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(volumeUpdateURL + '/' + id, data);
  }

  deleteVolume(id: any): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(volumeDeleteURL + '/' + id);
  }

  //products

  getAllProducts(pageNumber?: number, pageSize?: number, selectedStatus?: number, searchValue?: string, searchValueCategory?: string): Observable<any> {
    let endpoint = `${getAllProductsURL}`;
    if (pageNumber) {
      endpoint += `?pages=${pageNumber}&limit=${pageSize}&status=${selectedStatus}&name=${searchValue}&categoryName=${searchValueCategory}`;
    }
    return this.http.get<ApiResponse>(endpoint);
  }

  createProduct(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(createProductURL, data);
  }

  deleteProduct(id: any): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(deleteProductURL + '/' + id);
  }

  getProductDetails(productId: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${productDetailsURL}/${productId}`);
  }

  updateProduct(id: any, data: any): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(productUpdateURL + '/' + id, data);
  }

}
