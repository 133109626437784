import { Directive, HostListener } from '@angular/core';


@Directive({
    selector: '[restrictedExtraSymbol]',

})
export class RestrictedExtraSymbol {

    constructor() { }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void { // this used for when type=number
        if (event.key === 'e' || event.key === '+' || event.key === '-' || event.key === 'E') {
            event.preventDefault();
        }
    }
}


