import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from "rxjs";
import { AuthenticationService } from "../services/auth.service";
import { TokenStorageService } from "../services/token-storage.service";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // private toastr = inject(ToastrService);

  constructor(
    private authService: AuthenticationService,
    private tokenStorageService: TokenStorageService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.tokenStorageService.getToken();
    const refreshToken = this.tokenStorageService.getRefreshToken();

    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error: any) => {
        // this.toastr.error(error.error.message, "Error!");
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, refreshToken);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    refreshToken: any
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      this.addToken(request, refreshToken);
      return this.authService.refreshToken().pipe(
        switchMap((res: any) => {
          this.authService.saveResponse(res);
          this.isRefreshing = false;
          this.refreshTokenSubject.next(res.data.refreshToken);
          const updatedRequest = this.addToken(request, res.data.token); // Update request with new token
          return next.handle(updatedRequest);
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.authService.logout();
          this.router.navigateByUrl("/auth/login");
          return throwError(() => error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token: any) => {
          const updatedRequest = this.addToken(request, token); // Update request with new token
          return next.handle(updatedRequest);
        })
      );
    }
  }

}
