<app-breadcrumbs
  title="Edit Product"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>

<div
  class="row"
  *ngIf="!hasPermissionForProduct('UPDATE_PRODUCT')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForProduct('UPDATE_PRODUCT')">
  <div class="col-lg-12">
    <div class="card">
      <form
        [formGroup]="productUpdateForm"
        (ngSubmit)="updateProduct()"
        enctype="multipart/form-data"
      >
        <div class="card-header align-items-center d-flex">
          <h4 class="card-title mb-0 flex-grow-1">Product Information</h4>
        </div>
        <!-- end card header -->
        <div class="card-body">
          <div class="live-preview">
            <div class="row gy-4">
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="basiInput" class="form-label">Product Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    placeholder="Product Name"
                    id="basiInput"
                    appLimitInput
                    appIgnoreFirstCharacterMinus
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      productUpdateForm.get('name')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    Product Name is required
                  </p>
                </div>
              </div>

              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="basiInput" class="form-label"
                    >Product Status</label
                  >
                  <ng-select
                    placeholder="Select status"
                    id="filter"
                    [items]="activeStatus"
                    bindLabel="name"
                    class="my-dropdown"
                    formControlName="statusProduct"
                  >
                    <ng-template ng-optgroup-tmp let-item="item">
                      {{ item.name }}
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <label for="choices-single-default" class="form-label"
                  >Category</label
                >
                <ng-select
                  [items]="categories"
                  formControlName="category"
                  id="category"
                  bindLabel="name"
                  placeholder="Category"
                >
                  <ng-template ng-optgroup-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <p
                  *ngIf="
                    isSubmitted &&
                    productUpdateForm.get('category')?.hasError('required')
                  "
                  class="text-danger"
                >
                  Category is required
                </p>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <label for="choices-single-default" class="form-label"
                  >Volume</label
                >
                <ng-select
                  [items]="volumes"
                  formControlName="volume"
                  id="volume"
                  placeholder="Volume"
                  bindLabel="name"
                >
                  <ng-template ng-optgroup-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <p
                  *ngIf="
                    isSubmitted &&
                    productUpdateForm.get('volume')?.hasError('required')
                  "
                  class="text-danger"
                >
                  Volume is required
                </p>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="valueInput" class="form-label">SKU</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="sku"
                    placeholder="SKU"
                    appLimitInput
                    id="valueInput"
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      productUpdateForm.get('sku')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    SKU is required
                  </p>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="valueInput" class="form-label"
                    >Reorder Limit</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="rol"
                    formControlName="rol"
                    placeholder="Recorder Limit"
                    appLimitInput
                    restrictedExtraSymbol
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      productUpdateForm.get('rol')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    ROL is required
                  </p>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="valueInput" class="form-label"
                    >Product Price</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="price"
                    formControlName="price"
                    placeholder="Product Price"
                    appLimitInput
                    restrictedExtraSymbol
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      productUpdateForm.get('price')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    Price is required
                  </p>
                </div>
              </div>
              <!--end col-->

              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="valueInput" class="form-label"
                    >Product Quantity</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="qty"
                    formControlName="qty"
                    placeholder="Product Quantity"
                    appLimitInput
                    restrictedExtraSymbol
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      productUpdateForm.get('qty')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    Quantity is required
                  </p>
                </div>
              </div>
              <!-- -------------------------------- -->

              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      formControlName="isOutOfStock"
                      type="checkbox"
                      id="formCheck2"
                      [checked]="isOutOfStock"
                      value="true"
                    />
                    <label class="form-check-label" for="formCheck2">
                      Is Out of Stock
                    </label>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-xxl-6 col-md-6">
                <div>
                  <!-- Switches Color -->
                  <div class="form-check form-switch form-switch-md">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="SwitchCheck1"
                      formControlName="promotionStatus"
                    />
                    <label class="form-check-label" for="SwitchCheck1"
                      >Add to Promotion</label
                    >
                  </div>
                </div>
              </div>

              <div class="col-xxl-12 col-md-12">
                <label class="form-label"
                  >Current Product Images&nbsp;&nbsp;&nbsp;
                  <span class="image_formate">'png', 'jpg', 'jpeg'</span></label
                >

                <div *ngIf="images.length > 0" class="image-slider">
                  <div
                    *ngFor="let file of images; let i = index"
                    class="image-slide"
                  >
                    <img
                      [src]="file.path"
                      class="cropped-image"
                      (click)="openModal(file.path)"
                    />
                    <button
                      type="button"
                      class="remove-icon"
                      (click)="removeCurrentImage(i)"
                    >
                      ×
                    </button>
                  </div>
                </div>

                <input
                  type="file"
                  #fileInput
                  (change)="fileChangeEvent($event)"
                  id="imageUpload"
                  class="form-control"
                />
                <small class="text-muted">Maximum upload file size: 3MB</small>
                <!-- <p
                  *ngIf="isSubmitted && selectedFiles.length === 0"
                  class="text-danger"
                >
                  At least one image is required
                </p> -->
                <p class="text-danger m-0">{{ errorMessage }}</p>

                <div class="col-xxl-12 col-md-12" *ngIf="imageChangedEvent">
                  <div class="cropper-container">
                    <image-cropper
                      [imageChangedEvent]="imageChangedEvent"
                      [maintainAspectRatio]="false"
                      [aspectRatio]="cropperAspectRatio"
                      format="png"
                      (imageCropped)="imageCropped($event)"
                      (imageLoaded)="imageLoaded($event)"
                      (cropperReady)="cropperReady()"
                      (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                  </div>
                </div>

                <button
                  type="button"
                  *ngIf="imageChangedEvent"
                  class="btn btn-primary mt-2"
                  (click)="addCroppedImage()"
                >
                  Add Cropped Image
                </button>

                <button
                  type="button"
                  *ngIf="imageChangedEvent"
                  class="btn btn-danger mt-2 btn-margin"
                  (click)="removeCroppedImageEvent()"
                >
                  Reset
                </button>

                <div class="col-xxl-12 col-md-12 new-selected-image-list">
                  <label *ngIf="selectedFiles.length > 0" class="form-label"
                    >New Product Images</label
                  >

                  <div *ngIf="selectedFiles.length > 0" class="image-slider">
                    <div
                      *ngFor="let file of selectedFiles; let i = index"
                      class="image-slide"
                    >
                      <img
                        [src]="file.url"
                        class="cropped-image"
                        (click)="openModal(file.url)"
                      />
                      <button
                        type="button"
                        class="remove-icon"
                        (click)="removeCroppedImage(i)"
                      >
                        ×
                      </button>
                    </div>
                  </div>
                </div>

                <div *ngIf="showModal" class="modal" (click)="closeModal()">
                  <span class="close" (click)="closeModal()">&times;</span>
                  <div class="modal-content-container">
                    <img
                      [src]="modalImageSrc"
                      [ngStyle]="{ 'max-width.%': '80', 'max-height.%': '80' }"
                      class="modal-content"
                    />
                  </div>
                </div>
              </div>

              <div class="col-xxl-12 col-md-12">
                <label class="form-label">Product Description</label>
                <ckeditor
                  [editor]="Editor"
                  [config]="editorConfig"
                  formControlName="description"
                  [(ngModel)]="model.editorData"
                ></ckeditor>
                <p
                  *ngIf="
                    isSubmitted &&
                    productUpdateForm.get('description')?.hasError('required')
                  "
                  class="text-danger"
                >
                  Description is required
                </p>
              </div>

              <div class="col-xxl-12 col-md-12">
                <div>
                  <label for="seoTitle" class="form-label"
                    >Seo Title
                    <span class="required-star text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Seo title"
                    id="seoTitle"
                    formControlName="seoTitle"
                    appLimitInputLongDescription
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      productUpdateForm.get('seoTitle')?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Seo title is required
                  </p>
                </div>
              </div>

              <!--  -->
              <div class="col-xxl-12 col-md-12">
                <div>
                  <label for="seoDescription" class="form-label"
                    >Seo Description
                    <span class="required-star text-danger">*</span></label
                  >
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder="Seo description"
                    id="seoDescription"
                    formControlName="seoDescription"
                    rows="3"
                    appLimitInputLongDescription
                  ></textarea>
                  <p
                    *ngIf="
                      isSubmitted &&
                      productUpdateForm
                        .get('seoDescription')
                        ?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Seo description is required
                  </p>
                </div>
              </div>

              <!--end col-->

              <!-- <div class="col-xxl-12 col-md-12">
                <label for="choices-single-default" class="form-label"
                  >Additional Info</label
                >
                <div class="input-box skills" formArrayName="items">
                  <div
                    class="column"
                    *ngFor="let control of getControls(); let i = index"
                  >
                    <div [formGroupName]="i">
                      <div class="row">
                        <div class="col-12">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-12">
                                  <div class="mb-3">
                                    <label class="form-label">Title</label>
                                    <input
                                      formControlName="title"
                                      type="text"
                                      class="form-control"
                                      placeholder="Title"
                                      appLimitInput
                                    />
                                    <p
                                      *ngIf="
                                        isSubmitted &&
                                        control
                                          .get('title')
                                          ?.hasError('required')
                                      "
                                      class="text-danger"
                                    >
                                      Title is required
                                    </p>
                                  </div>
                                </div>
                                <div class="col-12">
                                  
                                  <div class="mb-3">
                                    <label class="form-label"
                                      >Description</label
                                    >
                                    <textarea
                                      class="form-control"
                                      id="exampleFormControlTextarea5"
                                      formControlName="description"
                                      rows="3"
                                      placeholder="Description"
                                      appLimitInputLongDescription
                                    ></textarea>
                                    <p
                                      *ngIf="
                                        isSubmitted &&
                                        control
                                          .get('description')
                                          ?.hasError('required')
                                      "
                                      class="text-danger"
                                    >
                                      Description is required
                                    </p>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    (click)="deleteInfo(i)"
                                  >
                                    <i class="ri-delete-bin-6-line"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="hideButton < 2" class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="addInfo()"
                  >
                    <i class="ri-add-line"></i>
                  </button>
                </div>
              </div> -->
            </div>
            <!--end row-->
          </div>
        </div>
        <div class="card-footer custom-tr">
          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light"
          >
            Update Product
          </button>
        </div>
      </form>
    </div>
  </div>
  <!--end col-->
</div>
<!--end row-->
<ngx-ui-loader></ngx-ui-loader>
