import { CommonModule } from "@angular/common";
import { Component, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { DashboardService } from "src/app/services/dashboard.service";
import { SharedModule } from "src/app/shared/shared.module";
import { hasPermission } from "src/app/store";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, SharedModule, NgApexchartsModule, NgxUiLoaderModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardComponent {
  breadCrumbItems!: Array<{}>;
  monthlySalesData: any = {};
  topSellingProducts: any[] = [];
  rangeAreaChartData: any;
  rangeAreaChartOptions: any;
  barChartOptionsRevCate: any = {};
  barchartDataRevCate: any[] = [];
  revenueByCategory: any[] = [];
  areaChartOptionsTopProduct: any = {};
  isLoading: boolean = true;

  constructor(
    private dashboardService: DashboardService,
    private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
    ];

    this.fetchMonthlySales();
    this.fetchTopSellingProducts();
    this.fetchRevenueByCategory();
  }

  hasPermissionForDashboard(permission: string) {
    return hasPermission(permission);
  }

  fetchMonthlySales(): void {
    this.ngxLoader.start();
    this.dashboardService.getMonthlySalesAnalytic().subscribe(
      (response) => {
        this.monthlySalesData = response.data;
      },
      (error) => {
        console.error('Error fetching monthly sales data:', error);
      },
      () => {
        this.checkLoadingState(); // Check loading state after completion
      }
    );
  }


  fetchTopSellingProducts(): void {
    this.dashboardService.getTopSellingAnalytic().subscribe(
      (response) => {
        this.topSellingProducts = response.data;

        // Prepare data for the pie chart
        this.rangeAreaChartData = this.topSellingProducts.map(product => ({
          x: product.productName,
          y: [product.minQty, product.maxQty]
        }));

        // Chart options
        this.rangeAreaChartOptions = {
          series: [{
            name: 'Quantity Range',
            data: this.rangeAreaChartData
          }],
          chart: {
            type: 'rangeArea',
            height: 350,
            toolbar: {
              show: true
            }
          },
          xaxis: {
            type: 'category',
            labels: {
              rotate: -45
            }
          },
          yaxis: {
            title: {
              text: 'Quantity'
            }
          },
          responsive: [
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 300,
                }
              }
            }
          ]
        };

        // Prepare data for the area chart (top selling product trends)
        this.areaChartOptionsTopProduct = {
          series: [
            {
              name: 'Sales',
              data: this.topSellingProducts.map(product => ({
                x: product.productName,
                y: product.totalQty
              }))
            }
          ],
          chart: {
            type: 'area',
            height: 350
          },
          xaxis: {
            categories: this.topSellingProducts.map(product => {
              let productName = product.productName;

              if (productName.length > 10) {
                productName = productName.substring(0, 10) + '...';
              }
              return productName;
            })
          },
          dataLabels: {
            enabled: true,
            // formatter: function (val: any, opts: any) {
            //   let productName = opts.w.config.xaxis.categories[opts.dataPointIndex];
            //   if (productName.length > 10) {
            //     productName = productName.substring(0, 10) + '...';
            //   }
            //   return productName + ": " + val;
            // },
            dropShadow: {
              enabled: false
            }
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: 'Bestselling Product Analysis',
            align: 'left'
          }
        };

        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching top selling products:', error);
        this.isLoading = false;
      }
    );
  }

  fetchRevenueByCategory(): void {
    this.dashboardService.getRevenueByCategoryAnalytic().subscribe(
      (response) => {
        this.revenueByCategory = response.data;

        // Prepare data for the chart
        this.barchartDataRevCate = this.revenueByCategory.map(item => ({
          x: item.categoryName,
          y: item.totalRevenue
        }));

        // Chart options
        this.barChartOptionsRevCate = {
          series: [
            {
              name: 'Revenue',
              data: this.barchartDataRevCate.map(dataItem => dataItem.y)
            }
          ],
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: this.barchartDataRevCate.map(dataItem => dataItem.x),
          },
          yaxis: {
            title: {
              text: 'Revenue'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val: any) {
                return '$' + val.toFixed(2);
              }
            }
          }
        };

        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching revenue by category:', error);
        this.isLoading = false;
      }
    );
  }

  checkLoadingState(): void {
    // Check if all data arrays have been initialized
    if (this.monthlySalesData.series && this.topSellingProducts.length > 0 && this.revenueByCategory.length > 0) {
      this.isLoading = false; // Set isLoading to false once all data is loaded
      this.ngxLoader.stop(); // Stop loader
    }
  }
}
