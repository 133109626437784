import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[inputValidate]',

})
export class InputValidatedirectivesDirective {

  @Input('inputValidate') inputType: 'text' | 'mobile' = 'text';

  constructor(private el: ElementRef, private ngControl: NgControl) { }
  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string, event: KeyboardEvent) {
    let filterValue = value;

    if (this.inputType === 'mobile') {
      filterValue = value.replace(/[^0-9]/g, '');
      const mobileRegex = /^07[0-9]{8}$/;

      if (!mobileRegex.test(filterValue)) {
        filterValue = filterValue.slice(0, 10);
      }
    } else if (this.inputType === 'text') {
      filterValue = value.replace(/[^A-Za-z\s.]/g, '');
    }
    this.ngControl.control?.setValue(filterValue, { emitEvent: false });
  }

}
