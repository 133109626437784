import { Component, ViewChild, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { RoleService } from "src/app/services/role.service";
import { NgbModal, NgbPagination } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/shared/shared.module";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { lastValueFrom } from "rxjs";
import { ApiResponse } from "src/app/models/api-response.model";
import Swal from "sweetalert2";
import { GeneralUtilityService } from "src/app/services/util.service";
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { hasPermission } from "src/app/store";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from '@angular/forms';

@Component({
  selector: "app-roles",
  standalone: true,
  imports: [CommonModule, NgbPagination, SharedModule, ReactiveFormsModule, NgxUiLoaderModule, NgSelectModule, FormsModule],
  templateUrl: "./roles.component.html",
  styleUrl: "./roles.component.scss",
})
export class RolesComponent {
  roles: any;
  role: any;
  breadCrumbItems!: Array<{}>;
  currentPage: number = 1;
  totalCount: number = 0;
  pageSize: number = 10;
  roleAddForm!: FormGroup;
  roleUpdateForm!: FormGroup;
  isSubmitted: boolean = false;
  @ViewChild("roleUpdateModal") editDataModal: any;
  roleStatus = [{ name: 'All', value: -1 }, { name: 'Active', value: 1 }, { name: 'Deactivate', value: 0 }];
  isSelected: any = this.roleStatus[0].value;
  roleNameSearch: any = ''
  isUpdateRoleStatus = [{ name: 'Activate', value: 1 }, { name: 'Deactivate', value: 0 }]

  private debounceRole: any;


  private generalUtilityService = inject(GeneralUtilityService);
  private modalService = inject(NgbModal);
  private toastr = inject(ToastrService);
  private roleService = inject(RoleService);
  private ngxLoader = inject(NgxUiLoaderService);

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Roles", active: true },
    ];

    this.getAllRoles(this.currentPage, this.pageSize, this.isSelected, this.roleNameSearch);
    this.roleAddForm = new FormGroup({
      roleName: new FormControl("", [Validators.required, Validators.pattern(`^[a-zA-Z\\s'-]+$`)]),
    });
    this.roleUpdateForm = new FormGroup({
      roleName: new FormControl("", [Validators.required, Validators.pattern(`^[a-zA-Z\\s'-]+$`)]),
      roleStatus: new FormControl(null)
    });
  }



  changeTheRole(event: any) {
    this.isSelected = event.value;
    this.getAllRoles(this.currentPage, this.pageSize, this.isSelected, this.roleNameSearch);
  }


  searchOnChangeRole(event: any) {
    clearTimeout(this.debounceRole);
    this.debounceRole = setTimeout(() => {
      this.roleNameSearch = event;
      this.getAllRoles(this.currentPage, this.pageSize, this.isSelected, this.roleNameSearch);
    }, 2000)

  }

  hasPermissionForRoles(permission: string) {
    return hasPermission(permission);
  }

  getAllRoles(pageNumber?: number, pageSize?: number, status?: number, roleNameSearch?: any) {
    let trimRoleName = roleNameSearch.replace(/^\s+|\s+$/gm, '');
    this.ngxLoader.start();
    this.roleService.getAllRoles(pageNumber, pageSize, status, trimRoleName).subscribe((response) => {
      this.roles = response.data.records;
      this.totalCount = response.data.totalRecords;
      this.ngxLoader.stop();
    });

  }

  openRoleModal(content: any) {
    this.modalService.open(content);
  }

  onCloseModal() {
    this.isSubmitted = false;
  }

  createNewRole() {
    this.isSubmitted = true;

    if (this.roleAddForm.valid) {
      this.ngxLoader.start();
      const data = {
        name: this.roleAddForm.value.roleName,
      };

      this.roleService.createRole(data).subscribe({
        next: (res) => {
          this.isSubmitted = false;
          this.roleAddForm.reset();
          this.modalService.dismissAll("close");
          this.toastr.success('Role created successfully', 'Success');
          //this.getAllRoles();
          this.getAllRoles(this.currentPage, this.pageSize, this.isSelected, this.roleNameSearch);
          this.ngxLoader.stop();
        },
        error: (e) => {
          this.isSubmitted = false;
          this.ngxLoader.stop();
        },
      });
    } else {
      this.toastr.warning('Role name is invalid !', 'warning');
    }

    //  this.ngxLoader.stop();
  }

  async getRoleDetails(content: any, roleId: any) {
    this.ngxLoader.start();
    const roleDetailRes$ = this.roleService.getRoleDetails(roleId);
    const roleDetailRes = lastValueFrom(roleDetailRes$);
    await roleDetailRes.then((response: ApiResponse) => {
      this.role = response.data;
      this.roleUpdateForm.patchValue({
        roleName: this.role.name,
        roleStatus: this.role.status !== 1 ? 'Deactivate' : 'Activate',
      });
      this.modalService.open(content);
    });
    this.ngxLoader.stop();
  }

  openEditModal(content: any, roleId: number) {
    this.getRoleDetails(content, roleId);
  }

  updateRole() {
    this.isSubmitted = true;

    if (this.roleUpdateForm.valid) {
      this.ngxLoader.start();
      const data = {
        name: this.roleUpdateForm.value.roleName,
        status: this.roleUpdateForm.value.roleStatus.value
      };

      this.roleService.updateRole(this.role.id, data).subscribe({
        next: (res) => {
          this.isSubmitted = false;
          this.toastr.success("Role Updated successfully", "Success");
          this.roleUpdateForm.reset();
          this.modalService.dismissAll("close");
          //this.getAllRoles();

          this.getAllRoles(this.currentPage, this.pageSize, this.isSelected, this.roleNameSearch);
          this.ngxLoader.stop();
        },
        error: (e) => {
          this.isSubmitted = false;
          this.ngxLoader.stop();
        },
      });
    } else {
      this.toastr.warning('Role name is invalid !', 'warning');
    }
    //this.ngxLoader.stop();
  }

  calculateEntryRange(): string {
    return this.generalUtilityService.getTblPaginationEntryRange(
      this.currentPage,
      this.pageSize,
      this.totalCount
    );
  }

  loadPage(pageNumber: number) {
    this.getAllRoles(pageNumber, this.pageSize, this.isSelected, this.roleNameSearch);
    //this.getAllRoles(this.currentPage, this.pageSize, this.isSelected, );
  }

  deleteRole(roleId: number) {
    Swal.fire({
      title: "Oops...",
      text: "Are you sure you want to delete this role?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(13, 109, 164)",
      cancelButtonColor: "rgb(243, 78, 78)",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {

        this.roleService.deleteRole(roleId).subscribe({
          next: (response: ApiResponse) => {
            Swal.fire({
              title: "Success",
              text: "Role Deleted",
              confirmButtonColor: "rgb(3, 142, 220)",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                //this.getAllRoles();
                this.getAllRoles(this.currentPage, this.pageSize, this.isSelected, this.roleNameSearch);
              }
            });
          },
          error: (e) => {
            // this.getAllFiles(this.parent_id, this.category_name);
          },
        });
      }
    });
  }

}
