import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appRestrictedTheSymbolValidation]',

})
export class RestrictedTheSymbolValidationDirective {

  constructor() { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === '^' || event.key === '/' ||
      event.key === '*' || event.key === '@' ||
      event.key === '%' || event.key === '!' ||
      event.key === '#' || event.key === '$' ||
      event.key === '_' || event.key === '&' ||
      event.key === '?' || event.key === ';' ||
      event.key === '=' || event.key === ':' ||
      event.key === ',' || event.key === '+' ||
      event.key === '<' || event.key === '>' ||
      event.key === ']' || event.key === '[' ||
      event.key === '|' || event.key === '}' ||
      event.key === '{') {
      event.preventDefault();
    }
  }

}

