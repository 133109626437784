<!-- Start Breadcrumbs -->
<app-breadcrumbs
  title="Order Details"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div
  class="row"
  *ngIf="!hasPermissionForOrders('VIEW_ORDER')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForOrders('VIEW_ORDER')">
  <div class="col-xl-9">
    <div class="card">
      <div class="card-header">
        <div class="d-flex align-items-center">
          <h5 class="card-title flex-grow-1 mb-0">Order #{{ order?.code }}</h5>
          <div class="flex-shrink-0">
            <!-- <a routerLink="/invoices/details" class="btn btn-primary btn-sm"><i class="ri-download-2-fill align-middle me-1"></i> Invoice</a> -->
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive table-card">
          <table class="table table-nowrap align-middle table-borderless mb-0">
            <thead class="table-light text-muted">
              <tr>
                <th scope="col">Product Details</th>
                <th scope="col">Item Price</th>
                <th scope="col">Quantity</th>
                <th scope="col" class="text-end">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              @for (item of order?.orderItems; track item; let i = $index) {
              <tr>
                <td>
                  <div class="d-flex">
                    <div class="flex-shrink-0 avatar-md bg-light rounded p-1">
                      <img
                        [src]="item.product.files[0].file.path"
                        alt=""
                        style="width: 100%; height: 100%; object-fit: contain"
                        class="img-fluid d-block"
                      />
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h5 class="fs-15">
                        <a
                          routerLink="/ecommerce/product-detail/1"
                          class="link-primary"
                          >{{ item.product.name }}</a
                        >
                      </h5>
                      <p class="text-muted mb-0">
                        Category:
                        <span class="fw-medium">{{
                          item.product.category.name
                        }}</span>
                      </p>
                      <p class="text-muted mb-0">
                        Volume:
                        <span class="fw-medium">{{
                          item.product.volume.name
                        }}</span>
                      </p>
                    </div>
                  </div>
                </td>
                <td>{{ item.price | number : "1.2-2" }}</td>
                <td>{{ item.qty }}</td>
                <td class="fw-medium text-end">
                  {{ +item.price * +item.qty | number : "1.2-2" }}
                </td>
              </tr>
              }
              <tr class="border-top border-top-dashed">
                <td colspan="2"></td>
                <td colspan="2" class="fw-medium p-0">
                  <table class="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td>Sub Total :</td>
                        <td class="text-end">
                          {{ order?.subTotal | number : "1.2-2" }}
                        </td>
                      </tr>
                      <!-- <tr>
                                                <td>Discount <span class="text-muted">(VELZON15)</span> : :</td>
                                                <td class="text-end">-$53.99</td>
                                            </tr> -->
                      <tr>
                        <td>Shipping Charge :</td>
                        <td class="text-end">00.00</td>
                      </tr>
                      <!-- <tr>
                                                <td>Estimated Tax :</td>
                                                <td class="text-end">$44.99</td>
                                            </tr> -->
                      <tr class="border-top border-top-dashed">
                        <th scope="row">Total (LKR) :</th>
                        <th class="text-end">
                          {{ order.subTotal | number : "1.2-2" }}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--end card-->

    <div class="card">
      <div class="card-header">
        <div class="d-sm-flex align-items-center">
          <h5 class="card-title flex-grow-1 mb-0">Order Status</h5>
          <div class="flex-shrink-0 mt-2 mt-sm-0">
            <div class="d-flex flex gap-1">
              <button
                *ngIf="order.orderStatus == 'Pending'"
                class="btn btn-soft-primary btn-sm mt-2 mt-sm-0"
                (click)="changeStatus('On Progress')"
              >
                <i class="ri-map-pin-line align-middle me-1"></i> Process Order
              </button>
              <button
                *ngIf="order.orderStatus == 'On Progress'"
                class="btn btn-soft-secondary btn-sm mt-2 mt-sm-0"
                (click)="changeStatus('Delivered')"
              >
                <i class="mdi mdi-archive-remove-outline align-middle me-1"></i>
                Mark As Delivered
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="profile-timeline order-status">
          <div ngbAccordion #acc="ngbAccordion" activeIds="static-1">
            <div ngbAccordionItem [collapsed]="true" id="static-1">
              <h2 ngbAccordionHeader>
                <button
                  ngbAccordionButton
                  class="accordion-button p-2 shadow-none"
                  data-bs-toggle="collapse"
                  href="javascript:void(0);"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 avatar-xs">
                      <div class="avatar-title bg-primary rounded-circle">
                        <i class="ri-shopping-bag-line"></i>
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h6 class="fs-14 mb-0">
                        Order Placed -
                        <span class="fw-normal">{{
                          order.createdAt | date : "EEE, dd MMM yyyy"
                        }}</span>
                      </h6>
                    </div>
                  </div>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body ms-2 ps-5 pt-0">
                        <h6 class="mb-1">An order has been placed.</h6>
                        <p class="text-muted">
                          {{
                            order.createdAt
                              | date : "EEE, dd MMM yyyy - hh:mm a"
                          }}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div
              ngbAccordionItem
              [collapsed]="order.orderStatus === 'Pending'"
              id="static-2"
            >
              <h2 ngbAccordionHeader>
                <button
                  ngbAccordionButton
                  class="accordion-button p-2 shadow-none"
                  data-bs-toggle="collapse"
                  href="javascript:void(0);"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 avatar-xs">
                      <!-- <div class="avatar-title bg-light text-primary rounded-circle">
                                                <i class="ri-truck-line"></i>
                                            </div> -->
                      <div
                        [ngClass]="{
                                                'bg-light': order.orderStatus === 'Pending',
                                                'bg-primary': order.orderStatus === 'Delivered' ||order.orderStatus === 'On Progress',
                                                'text-primary': order.orderStatus === 'Pending',
                                              }"
                        class="avatar-title rounded-circle"
                      >
                        <i class="ri-truck-line"></i>
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h6 class="fs-14 mb-1">Processing</h6>
                    </div>
                  </div>
                </button>
              </h2>
              <!-- <div ngbAccordionCollapse>
                                <div ngbAccordionBody>
                                    <ng-template>
                                        <div class="accordion-body ms-2 ps-5 pt-0">
                                          
                                            <h6 class="mb-1">Your item has been shipped.</h6>
                                            <p class="text-muted mb-0">Sat, 18 Dec 2021 - 4.54PM</p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div> -->
            </div>
            <div ngbAccordionItem id="static-3">
              <h2 ngbAccordionHeader>
                <button
                  ngbAccordionButton
                  class="accordion-button p-2 shadow-none"
                  data-bs-toggle="collapse"
                  href="javascript:void(0);"
                  aria-expanded="false"
                >
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 avatar-xs">
                      <div
                        class="avatar-title rounded-circle"
                        [ngClass]="{
                                                'bg-light': order.orderStatus === 'On Progress'|| order.orderStatus === 'Pending',
                                                'bg-primary': order.orderStatus === 'Delivered',
                                                'text-primary':  order.orderStatus === 'On Progress' || order.orderStatus === 'Pending',
                                              }"
                        class="avatar-title rounded-circle"
                      >
                        <i class="mdi mdi-package-variant"></i>
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h6 class="fs-14 mb-0">Delivered</h6>
                    </div>
                  </div>
                </button>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="card">
            <div class="card-header">
                <div class="d-sm-flex align-items-center">
                    <h5 class="card-title flex-grow-1 mb-0">Order Status</h5>
                    <div class="flex-shrink-0 mt-2 mt-sm-0">
                        <div class="d-flex flex gap-1">
                            <a href="javasccript:void(0;)" class="btn btn-soft-primary btn-sm mt-2 mt-sm-0"><i class="ri-map-pin-line align-middle me-1"></i> Change Address</a>
                            <a href="javasccript:void(0;)" class="btn btn-soft-secondary btn-sm mt-2 mt-sm-0"><i class="mdi mdi-archive-remove-outline align-middle me-1"></i> Cancel Order</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="profile-timeline order-status">
                    <div ngbAccordion #acc="ngbAccordion" activeIds="static-1">
                        <div ngbAccordionItem [collapsed]="false" id="static-1">
                            <h2 ngbAccordionHeader>
                                <button ngbAccordionButton class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="javascript:void(0);" aria-expanded="true" aria-controls="collapseOne">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 avatar-xs">
                                            <div class="avatar-title bg-primary rounded-circle">
                                                <i class="ri-shopping-bag-line"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h6 class="fs-14 mb-0">Order Placed - <span class="fw-normal">Wed, 15 Dec 2021</span></h6>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div ngbAccordionCollapse>
                                <div ngbAccordionBody>
                                    <ng-template>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body ms-2 ps-5 pt-0">
                                                <h6 class="mb-1">An order has been placed.</h6>
                                                <p class="text-muted">Wed, 15 Dec 2021 - 05:34PM</p>

                                                <h6 class="mb-1">Seller has processed your order.</h6>
                                                <p class="text-muted mb-0">Thu, 16 Dec 2021 - 5:48AM</p>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div ngbAccordionItem [collapsed]="false" id="static-2">
                            <h2 ngbAccordionHeader>
                                <button ngbAccordionButton class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="javascript:void(0);" aria-expanded="false" aria-controls="collapseTwo">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 avatar-xs">
                                            <div class="avatar-title bg-primary rounded-circle">
                                                <i class="mdi mdi-gift-outline"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h6 class="fs-14 mb-1">Packed - <span class="fw-normal">Thu, 16 Dec 2021</span></h6>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div ngbAccordionCollapse>
                                <div ngbAccordionBody>
                                    <ng-template>
                                        <div class="accordion-body ms-2 ps-5 pt-0">
                                            <h6 class="mb-1">Your Item has been picked up by courier partner</h6>
                                            <p class="text-muted mb-0">Fri, 17 Dec 2021 - 9:45AM</p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div ngbAccordionItem [collapsed]="false" id="static-3">
                            <h2 ngbAccordionHeader>
                                <button ngbAccordionButton class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="javascript:void(0);" aria-expanded="false" aria-controls="collapseThree">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 avatar-xs">
                                            <div class="avatar-title bg-primary rounded-circle">
                                                <i class="ri-truck-line"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h6 class="fs-14 mb-1">Shipping - <span class="fw-normal">Thu, 16 Dec 2021</span></h6>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div ngbAccordionCollapse>
                                <div ngbAccordionBody>
                                    <ng-template>
                                        <div class="accordion-body ms-2 ps-5 pt-0">
                                            <h6 class="fs-14">RQK Logistics - MFDS1400457854</h6>
                                            <h6 class="mb-1">Your item has been shipped.</h6>
                                            <p class="text-muted mb-0">Sat, 18 Dec 2021 - 4.54PM</p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div ngbAccordionItem [collapsed]="false" id="static-4">
                            <h2 ngbAccordionHeader>
                                <button ngbAccordionButton class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="javascript:void(0);" aria-expanded="false">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 avatar-xs">
                                            <div class="avatar-title bg-light text-primary rounded-circle">
                                                <i class="ri-takeaway-fill"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h6 class="fs-14 mb-0">Out For Delivery</h6>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                        </div>
                        <div ngbAccordionItem id="static-5">
                            <h2 ngbAccordionHeader>
                                <button ngbAccordionButton class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="javascript:void(0);" aria-expanded="false">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 avatar-xs">
                                            <div class="avatar-title bg-light text-primary rounded-circle">
                                                <i class="mdi mdi-package-variant"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h6 class="fs-14 mb-0">Delivered</h6>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
  <!--end col-->
  <div class="col-xl-3">
    <!-- <div class="card">
            <div class="card-header">
                <div class="d-flex">
                    <h5 class="card-title flex-grow-1 mb-0"><i class="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i> Logistics Details</h5>
                    <div class="flex-shrink-0">
                        <a href="javascript:void(0);" class="badge bg-primary-subtle text-primary fs-11">Track Order</a>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="text-center">
                    <h5 class="fs-16 mt-2">RQK Logistics</h5>
                    <p class="text-muted mb-0">ID: MFDS1400457854</p>
                    <p class="text-muted mb-0">Payment Mode : Debit Card</p>
                </div>
            </div>
        </div> -->

    <div class="card">
      <div class="card-header">
        <div class="d-flex">
          <h5 class="card-title flex-grow-1 mb-0">Customer Details</h5>
          <!-- <div class="flex-shrink-0">
                        <a href="javascript:void(0);" routerLink="/pages/profile" class="link-secondary">View Profile</a>
                    </div> -->
        </div>
      </div>
      <div class="card-body">
        <ul class="list-unstyled mb-0 vstack gap-3">
          <li>
            <div class="d-flex align-items-center">
              <!-- <div class="flex-shrink-0">
                                <img src="assets/images/users/avatar-3.jpg" alt="" class="avatar-sm rounded">
                            </div> -->
              <div class="flex-grow-1 ms-3">
                <h6 class="fs-14 mb-1">{{ order.billingAddress.fullName }}</h6>
                <!-- <p class="text-muted mb-0">Customer</p> -->
              </div>
            </div>
          </li>
          <li>
            <i class="ri-mail-line me-2 align-middle text-muted fs-16"></i
            >{{ order.email }}
          </li>
          <li>
            <i class="ri-phone-line me-2 align-middle text-muted fs-16"></i
            >{{ order.contactNo }}
          </li>
        </ul>
      </div>
    </div>
    <!--end card-->
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">
          <i class="ri-map-pin-line align-middle me-1 text-muted"></i> Billing
          Address
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-unstyled vstack gap-2 fs-13 mb-0">
          <li class="fw-medium fs-14">{{ order.billingAddress.fullName }}</li>
          <li>{{ order.contactNo }}</li>
          <li>{{ order.billingAddress.address }}</li>
          <li>
            {{ order.billingAddress.city }} -
            {{ order.billingAddress.postalCode }}
          </li>
          <li>{{ order.billingAddress.country }}</li>
        </ul>
      </div>
    </div>
    <!--end card-->
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">
          <i class="ri-map-pin-line align-middle me-1 text-muted"></i> Shipping
          Address
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-unstyled vstack gap-2 fs-13 mb-0">
          <li class="fw-medium fs-14">{{ order.shippingAddress.fullName }}</li>
          <li>{{ order.contactNo }}</li>
          <li>{{ order.shippingAddress.address }}</li>
          <li>
            {{ order.shippingAddress.city }} -
            {{ order.shippingAddress.postalCode }}
          </li>
          <li>{{ order.shippingAddress.country }}</li>
        </ul>
      </div>
    </div>
    <!--end card-->

    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">
          <i class="ri-secure-payment-line align-bottom me-1 text-muted"></i>
          Payment Details
        </h5>
      </div>
      <div class="card-body">
        <!-- <div class="d-flex align-items-center mb-2">
                    <div class="flex-shrink-0">
                        <p class="text-muted mb-0">Transactions:</p>
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="mb-0">#VLZ124561278124</h6>
                    </div>
                </div> -->
        <div class="d-flex align-items-center mb-2">
          <div class="flex-shrink-0">
            <p class="text-muted mb-0">Payment Method:</p>
          </div>
          <div class="flex-grow-1 ms-2">
            <h6 class="mb-0">{{ order.paymentMethod }}</h6>
          </div>
        </div>
        <!-- <div class="d-flex align-items-center mb-2">
                    <div class="flex-shrink-0">
                        <p class="text-muted mb-0">Card Holder Name:</p>
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="mb-0">Joseph Parker</h6>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-2">
                    <div class="flex-shrink-0">
                        <p class="text-muted mb-0">Card Number:</p>
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="mb-0">xxxx xxxx xxxx 2456</h6>
                    </div>
                </div> -->
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <p class="text-muted mb-0">Total Amount:</p>
          </div>
          <div class="flex-grow-1 ms-2">
            <h6 class="mb-0">{{ order.subTotal }} LKR</h6>
          </div>
        </div>
      </div>
    </div>
    <!--end card-->
  </div>
  <!--end col-->
</div>
<!--end row-->
<ngx-ui-loader></ngx-ui-loader>
