import { Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { GeneralUtilityService } from 'src/app/services/util.service';
import { BlogService } from 'src/app/services/blog.service';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response.model';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { hasPermission } from 'src/app/store';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';


@Component({
  selector: 'app-blog-category',
  standalone: true,
  imports: [CommonModule, NgbPagination, SharedModule, ReactiveFormsModule, NgxUiLoaderModule, FormsModule, NgSelectModule],
  templateUrl: './blog-category.component.html',
  styleUrl: './blog-category.component.scss'
})
export class BlogCategoryComponent {

  blogCategories: any;
  blogCategory: any;
  breadCrumbItems!: Array<{}>;
  currentPage: number = 1;
  totalCount: number = 0;
  pageSize: number = 10;
  categoryAddForm!: FormGroup;
  categoryUpdateForm!: FormGroup;
  isSubmitted: boolean = false;
  blogCategoryStatus = [{ name: 'All', value: -1 }, { name: 'Activate', value: 1 }, { name: 'Deactivate', value: 0 }]
  isSelectedCategory: any = this.blogCategoryStatus[0].value;
  defaultSelect = 'All';
  statusForUpdate = [{ name: 'Activated', value: 1 }, { name: 'Deactivated', value: 0 }];
  searchValueBlogCategory: any = '';
  debounceFilterRation: any



  @ViewChild("categoryUpdateModal") editDataModal: any;

  private generalUtilityService = inject(GeneralUtilityService);
  private modalService = inject(NgbModal);
  private ngxLoader = inject(NgxUiLoaderService);
  private blogService = inject(BlogService);
  private toastr = inject(ToastrService);

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Blog Categories", active: true },
    ];

    this.getAllBlogCategories(this.currentPage, this.pageSize, this.isSelectedCategory, this.searchValueBlogCategory);
    this.categoryAddForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
    });
    this.categoryUpdateForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      categoryStatus: new FormControl(null)
    });
  }


  searchOnChangeBlogCategory(event: any) {
    clearTimeout(this.debounceFilterRation);
    this.debounceFilterRation = setTimeout(() => {
      this.searchValueBlogCategory = event;
      this.getAllBlogCategories(this.currentPage, this.pageSize, this.isSelectedCategory, this.searchValueBlogCategory);
    }, 1000)
  }

  hasPermissionForBlogs(permission: string) {
    return hasPermission(permission);
  }

  changeOnStatus(event: any) {
    this.isSelectedCategory = event.value;
    this.getAllBlogCategories(this.currentPage, this.pageSize, this.isSelectedCategory, this.searchValueBlogCategory);

  }

  getAllBlogCategories(pageNumber?: number, pageSize?: number, isSelectedCategory?: number, searchValueBlogCategory?: any) {
    let trimValueBlogCategory = this.searchValueBlogCategory.replace(/^\s+|\s+$/gm, '');
    this.ngxLoader.start();
    this.blogService.getAllBlogCategories(pageNumber, pageSize, isSelectedCategory, trimValueBlogCategory).subscribe((response) => {
      this.blogCategories = response.data.records;
      this.totalCount = response.data.totalRecords;
    });
    this.ngxLoader.stop();
  }



  loadPage(pageNumber: number) {
    this.getAllBlogCategories(pageNumber, this.pageSize, this.isSelectedCategory, this.searchValueBlogCategory);
  }


  openCategoryAddModal(content: any) {
    this.modalService.open(content);
  }

  openEditModal(content: any, roleId: number) {
    this.getCategoryDetails(content, roleId);
  }

  async getCategoryDetails(content: any, catId: any) {
    this.ngxLoader.start();
    const roleDetailRes$ = this.blogService.getCategoryDetails(catId);
    const roleDetailRes = lastValueFrom(roleDetailRes$);
    await roleDetailRes.then((response: ApiResponse) => {
      this.blogCategory = response.data;
      this.categoryUpdateForm.patchValue({
        name: this.blogCategory.name,
        categoryStatus: this.blogCategory.status != 1 ? 'Deactivated' : 'Activated'
      });
      this.modalService.open(content);
    });
    this.ngxLoader.stop();
  }

  calculateEntryRange(): string {
    return this.generalUtilityService.getTblPaginationEntryRange(
      this.currentPage,
      this.pageSize,
      this.totalCount
    );
  }

  onModalClose() {
    this.isSubmitted = false;
  }

  createNewCategory() {
    this.isSubmitted = true;
    if (this.categoryAddForm.valid) {
      this.ngxLoader.start();
      const data = {
        name: this.categoryAddForm.value.name,
      };

      this.blogService.createCategory(data).subscribe({
        next: (res) => {
          this.isSubmitted = false;
          this.categoryAddForm.reset();
          this.toastr.success('Blog category save successfully', 'Success!')
          this.modalService.dismissAll("close");
          this.getAllBlogCategories(this.currentPage, this.pageSize, this.isSelectedCategory, this.searchValueBlogCategory);
        },
        error: (e) => {
          this.isSubmitted = false;
        },
      });
    }
    this.ngxLoader.stop();
  }

  updateBlogCategory() {
    this.isSubmitted = true;
    this.ngxLoader.start();
    if (this.categoryUpdateForm.valid) {
      const data = {
        name: this.categoryUpdateForm.value.name,
        status: this.categoryUpdateForm.value.categoryStatus.value
      };

      this.blogService.updateCategory(this.blogCategory.id, data).subscribe({
        next: (res) => {
          this.isSubmitted = false;
          this.toastr.success("Blog category updated successfully", "Success!");
          this.categoryUpdateForm.reset();
          this.modalService.dismissAll("close");
          this.getAllBlogCategories(this.currentPage, this.pageSize, this.isSelectedCategory, this.searchValueBlogCategory);
        },
        error: (e) => {
          this.isSubmitted = false;
        },
      });
    }
    this.ngxLoader.stop();
  }

  deleteCategory(catId: number) {
    Swal.fire({
      title: "Oops...",
      text: "Are you sure you want to delete this blog category ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(13, 109, 164)",
      cancelButtonColor: "rgb(243, 78, 78)",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {

        this.blogService.deleteCategory(catId).subscribe({
          next: (response: ApiResponse) => {
            Swal.fire({
              title: "Success!",
              text: "Blog Category Deleted",
              confirmButtonColor: "rgb(3, 142, 220)",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                this.getAllBlogCategories(this.currentPage, this.pageSize, this.isSelectedCategory, this.searchValueBlogCategory);
              }
            });
          },
          error: (e) => {
            // this.getAllFiles(this.parent_id, this.category_name);
          },
        });
      }
    });
  }
}
