<!-- start Work Process -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="text-center mb-5">
                    <h3 class="mb-3 fw-semibold">Our Work Process</h3>
                    <p class="text-muted mb-4 ff-secondary">In an ideal world this website wouldn’t exist, a client would
                        acknowledge the importance of having web copy before the Proin vitae ipsum vel ex
                        finibus semper design starts.</p>
                </div>
            </div>
        </div>
        <!-- end row -->

        <div class="row text-center">
            @for (data of Process; track $index) {
            <div class="col-lg-4">
                <div class="process-card mt-4">
                    <div class="process-arrow-img d-none d-lg-block">
                        @if(data.title != 'Deliver high quality product'){
                        <img src="assets/images/landing/process-arrow-img.png" alt="" class="img-fluid">
                        }
                    </div>
                    <div class="avatar-sm icon-effect mx-auto mb-4">
                        <div class="avatar-title bg-transparent text-success rounded-circle h1">
                            <i class="{{data.icon}}"></i>
                        </div>
                    </div>

                    <h5>{{data.title}}</h5>
                    <p class="text-muted ff-secondary">{{data.content}}</p>
                </div>
            </div>}
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end Work Process -->