<div class="card col-lg-12">
  <div class="card-body">
    <form [formGroup]="profileForm" (submit)="onSubmit()">
      <div class="row">
        <h4 class="card-title mb-0 flex-grow-1 mb-4">Change Profile Details</h4>
        <div class="col-lg-12">
          <div class="mb-4">
            <label for="firstnameInput" class="form-label"
              >First Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="firstnameInput"
              formControlName="firstName"
              placeholder="Enter first name"
            />
            <p
              class="text-danger"
              *ngIf="
                isSubmitted &&
                this.profileForm.get('firstName')?.hasError('required')
              "
            >
              First name is required
            </p>
          </div>
        </div>
        <!--end col-->
        <div class="col-lg-12">
          <div class="mb-4">
            <label for="lastnameInput" class="form-label"
              >Last Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="lastnameInput"
              formControlName="lastName"
              placeholder="Enter last name"
            />
            <p
              class="text-danger"
              *ngIf="
                isSubmitted &&
                this.profileForm.get('lastName')?.hasError('required')
              "
            >
              Last name is required
            </p>
          </div>
        </div>
        <!--end col-->
        <div class="col-lg-12">
          <div class="mb-4">
            <label for="phonenumberInput" class="form-label"
              >Contact Number <span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              formControlName="contactNumber"
              id="phonenumberInput"
              placeholder="Enter phone number"
            />
            <p
              class="text-danger"
              *ngIf="
                isSubmitted &&
                this.profileForm.get('contactNumber')?.hasError('required')
              "
            >
              Contact number is required
            </p>
          </div>
        </div>
        <!--end col-->
        <div class="col-lg-12">
          <div class="mb-4">
            <label for="addressInput" class="form-label">Address</label>
            <input
              type="text"
              class="form-control"
              formControlName="address"
              id="addressInput"
              placeholder="Enter your address"
            />
          </div>
        </div>

        <!-- end col -->
        <div class="col-lg-12">
          <div class="mb-4">
            <label for="EmailInput" class="form-label">E-mail</label>
            <input
              type="email"
              formControlName="email"
              class="form-control"
              id="EmailInput"
              placeholder="Enter your email"
            />
          </div>
        </div>

        <div class="card-footer custom-tr text-end">
          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light mt-3"
          >
            Update Personal Details
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card col-lg-12">
  <div class="card-body">
    <form [formGroup]="passwordForm" (submit)="changePassword()">
      <div class="row">
        <h4 class="card-title mb-0 mb-4">Change Password</h4>
        <div class="col-lg-12">
          <div class="mb-4">
            <label for="EmailInput" class="form-label"
              >Old Password <span class="text-danger">*</span></label
            >
            <input
              [type]="fieldTextType ? 'text' : 'password'"
              class="form-control"
              formControlName="oldPassword"
              id="EmailInput"
              placeholder="Enter old password"
            />
            <i
              [ngClass]="{
                'mdi-eye-off-outline': !fieldTextType,
                'mdi-eye-outline': fieldTextType
              }"
              class="mdi align-middle position-absolute"
              style="
                right: 23px;
                top: 55%;
                transform: translateY(-50%);
                cursor: pointer;
                color: #1a2262;
              "
              (click)="eyeOnAction()"
            ></i>

            <p
              class="text-danger"
              *ngIf="
                isSubmitted &&
                this.passwordForm.get('oldPassword')?.hasError('required')
              "
            >
              Old password is required
            </p>
          </div>
        </div>
        <!-- end col -->
        <div class="col-lg-12">
          <div class="mb-4">
            <label for="newPasswords" class="form-label"
              >New Password <span class="text-danger">*</span></label
            >
            <input
              [type]="fieldTextTypeTwo ? 'text' : 'password'"
              class="form-control"
              id="newPasswords"
              formControlName="newPassword"
              placeholder="Enter new password"
            />
            <i
              [ngClass]="{
                'mdi-eye-off-outline': !fieldTextTypeTwo,
                'mdi-eye-outline': fieldTextTypeTwo
              }"
              class="mdi align-middle position-absolute"
              style="
                right: 23px;
                top: 55%;
                transform: translateY(-50%);
                cursor: pointer;
                color: #1a2262;
              "
              (click)="eyeOnActionTwo()"
            ></i>
            <p
              class="text-danger"
              *ngIf="
                isSubmitted &&
                this.passwordForm.get('newPassword')?.hasError('required')
              "
            >
              New password is required
            </p>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="mb-5">
            <label for="retype" class="form-label"
              >Confirm password <span class="text-danger">*</span></label
            >
            <input
              [type]="fieldTextTypeThree ? 'text' : 'password'"
              class="form-control"
              id="retype"
              formControlName="confirmPassword"
              placeholder="Re-Enter new password"
            />
            <i
              [ngClass]="{
                'mdi-eye-off-outline': !fieldTextTypeThree,
                'mdi-eye-outline': fieldTextTypeThree
              }"
              class="mdi align-middle position-absolute"
              style="
                right: 23px;
                top: 43%;
                transform: translateY(-50%);
                cursor: pointer;
                color: #1a2262;
              "
              (click)="eyeOnActionThree()"
            ></i>
            <p
              class="text-danger m-0"
              *ngIf="
                isSubmitted &&
                this.passwordForm.get('confirmPassword')?.hasError('required')
              "
            >
              Confirm password is required
            </p>
            <p class="text-danger" *ngIf="notSameError">
              New Password and conform password did not match
            </p>
          </div>
        </div>

        <div class="card-footer custom-tr text-end">
          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light mt-3"
          >
            Update Password
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!--  -->

<ngx-ui-loader></ngx-ui-loader>
