import { Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { GeneralUtilityService } from 'src/app/services/util.service';
import { NgbModal, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SharedModule } from 'src/app/shared/shared.module';
import { ApiResponse } from 'src/app/models/api-response.model';
import Swal from 'sweetalert2';
import { lastValueFrom } from 'rxjs';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { hasPermission } from 'src/app/store';
import { NgSelectModule } from "@ng-select/ng-select";

@Component({
  selector: 'app-volumes',
  standalone: true,
  imports: [CommonModule, NgbPagination, SharedModule, ReactiveFormsModule, FormsModule, NgxUiLoaderModule, NgSelectModule],
  templateUrl: './volumes.component.html',
  styleUrl: './volumes.component.scss'
})
export class VolumesComponent {

  volumes: any;
  volume: any;
  breadCrumbItems!: Array<{}>;
  currentPage: number = 1;
  totalCount: number = 0;
  pageSize: number = 10;
  volumeAddForm!: FormGroup;
  volumeUpdateForm!: FormGroup;
  isSubmitted: boolean = false;
  volumeStatus = [{ name: 'All', value: '-1' }, { name: 'Active', value: '1' }, { name: 'Deactivate', value: '0' }]
  isSelectedStatus: any = this.volumeStatus[0].value;
  tempSelect: any = 'All'
  updateForVolumeStatus: any = [{ name: 'Activate', value: 1 }, { name: 'Deactivated', value: 0 }]

  @ViewChild("volumeUpdateModal") editDataModal: any;

  private modalService = inject(NgbModal);
  private generalUtilityService = inject(GeneralUtilityService);
  private productService = inject(ProductService);
  private toastr = inject(ToastrService);
  private ngxLoader = inject(NgxUiLoaderService);

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Product Volumes", active: true },
    ];

    this.getAllVolumes(this.currentPage, this.pageSize, this.isSelectedStatus);
    this.volumeAddForm = new FormGroup({
      Name: new FormControl("", [Validators.required]),
    });
    this.volumeUpdateForm = new FormGroup({
      Name: new FormControl("", [Validators.required]),
      valueStatus: new FormControl(null)
    });
  }

  changeVolumeStatus(event: any) {
    this.isSelectedStatus = event.value;
    //console.log('hey', this.isSelectedStatus);
    this.getAllVolumes(this.currentPage, this.pageSize, this.isSelectedStatus);

  }



  hasPermissionForVolume(permission: string) {
    return hasPermission(permission);
  }

  getAllVolumes(pageNumber?: number, pageSize?: number, isSelectedStatus?: number) {
    this.ngxLoader.start();
    this.productService.getAllVolumes(pageNumber, pageSize, isSelectedStatus).subscribe((response) => {
      this.volumes = response.data.records;
      this.totalCount = response.data.totalRecords;
    });
    this.ngxLoader.stop();
  }

  openRoleModal(content: any) {
    this.modalService.open(content);
  }

  openEditModal(content: any, roleId: number) {
    this.getVolumeDetails(content, roleId);
  }

  async getVolumeDetails(content: any, roleId: any) {
    this.ngxLoader.start();
    const roleDetailRes$ = this.productService.getVolumeDetails(roleId);
    const roleDetailRes = lastValueFrom(roleDetailRes$);
    await roleDetailRes.then((response: ApiResponse) => {
      this.volume = response.data;
      this.volumeUpdateForm.patchValue({
        Name: this.volume.volume,
        valueStatus: this.volume.status != 1 ? 'Deactivated' : 'Activate'
      });
      this.modalService.open(content);
    });
    this.ngxLoader.stop();
  }

  deleteVolume(volId: number) {
    Swal.fire({
      title: "Oops...",
      text: "Are you sure you want to delete this volume?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(13, 109, 164)",
      cancelButtonColor: "rgb(243, 78, 78)",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {

        this.productService.deleteVolume(volId).subscribe({
          next: (response: ApiResponse) => {
            Swal.fire({
              title: "Success",
              text: "Volume deleted successfully",
              confirmButtonColor: "rgb(3, 142, 220)",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                // this.getAllVolumes();
                this.getAllVolumes(this.currentPage, this.pageSize, this.isSelectedStatus);
              }
            });
          },
          error: (e) => {
            // this.getAllFiles(this.parent_id, this.category_name);
          },
        });
      }
    });
  }

  calculateEntryRange(): string {
    return this.generalUtilityService.getTblPaginationEntryRange(
      this.currentPage,
      this.pageSize,
      this.totalCount
    );
  }

  loadPage(pageNumber: number) {
    this.getAllVolumes(pageNumber, this.pageSize);
  }

  onModalClose() {
    this.isSubmitted = false;
  }


  createNewVolume() {
    this.isSubmitted = true;
    this.ngxLoader.start();
    if (this.volumeAddForm.valid) {

      let name = this.volumeAddForm.value.Name + 'ml';
      // console.log("yess",name);

      const data = {
        name: name,
        volume: this.volumeAddForm.value.Name,
      };

      this.productService.createVolume(data).subscribe({
        next: (res) => {
          this.isSubmitted = false;
          this.volumeAddForm.reset();
          this.toastr.success("Volume save successfully", "Success!");
          this.modalService.dismissAll("close");
          //this.getAllVolumes();
          this.getAllVolumes(this.currentPage, this.pageSize, this.isSelectedStatus);
        },
        error: (e) => {
          this.isSubmitted = false;
        },
      });
    }
    this.ngxLoader.stop();
  }

  updateVolume() {
    this.ngxLoader.start();
    this.isSubmitted = true;
    if (this.volumeUpdateForm.valid) {
      let name = this.volumeUpdateForm.value.Name + 'ml';
      const data = {
        name: name,
        volume: this.volumeUpdateForm.value.Name,
        status: this.volumeUpdateForm.value.valueStatus.value,
      };
      console.log('my data', data);



      this.productService.updateVolume(this.volume.id, data).subscribe({
        next: (res: any) => {
          this.isSubmitted = false;
          this.toastr.success("Volume updated successfully", "Success");
          this.volumeUpdateForm.reset();
          this.modalService.dismissAll("close");
          //this.getAllVolumes();
          this.getAllVolumes(this.currentPage, this.pageSize, this.isSelectedStatus);
        },
        error: (e: any) => {
          this.isSubmitted = false;
        },
      });
    }
    this.ngxLoader.stop();
  }
}
