<app-breadcrumbs
  title="Dashboard"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>

<div
  class="row"
  *ngIf="!hasPermissionForDashboard('VIEW_ANALYTICS')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<ngx-ui-loader *ngIf="isLoading"></ngx-ui-loader>

<div
  class="container-fluid"
  *ngIf="hasPermissionForDashboard('VIEW_ANALYTICS') && !isLoading"
>
  <div class="row">
    <!-- Monthly Sales Chart -->
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Monthly Sales</h5>
          <apx-chart
            *ngIf="monthlySalesData.series"
            [series]="monthlySalesData.series"
            [chart]="monthlySalesData.chart"
            [xaxis]="monthlySalesData.xaxis"
            [dataLabels]="monthlySalesData.dataLabels"
            [grid]="monthlySalesData.grid"
            [stroke]="monthlySalesData.stroke"
            [title]="monthlySalesData.title"
          ></apx-chart>
        </div>
      </div>
    </div>

    <!-- Top Selling Products (Pie Chart) -->
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Top Selling Product Trends</h5>
          <apx-chart
            *ngIf="rangeAreaChartData && rangeAreaChartData.length > 0"
            [series]="rangeAreaChartOptions.series"
            [chart]="rangeAreaChartOptions.chart"
            [xaxis]="rangeAreaChartOptions.xaxis"
            [yaxis]="rangeAreaChartOptions.yaxis"
            [responsive]="rangeAreaChartOptions.responsive"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>

  <!-- Revenue by Category and Top Selling Product Trends (Area Chart) -->
  <div class="row">
    <!-- Revenue by Category -->
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Revenue by Category</h5>
          <apx-chart
            *ngIf="barchartDataRevCate.length > 0"
            [series]="barChartOptionsRevCate.series"
            [chart]="barChartOptionsRevCate.chart"
            [plotOptions]="barChartOptionsRevCate.plotOptions"
            [dataLabels]="barChartOptionsRevCate.dataLabels"
            [xaxis]="barChartOptionsRevCate.xaxis"
            [yaxis]="barChartOptionsRevCate.yaxis"
            [fill]="barChartOptionsRevCate.fill"
            [tooltip]="barChartOptionsRevCate.tooltip"
          ></apx-chart>
        </div>
      </div>
    </div>

    <!-- Bestselling Product Analysis (Area Chart) -->
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Bestselling Product Analysis</h5>
          <apx-chart
            *ngIf="areaChartOptionsTopProduct.series"
            [series]="areaChartOptionsTopProduct.series"
            [chart]="areaChartOptionsTopProduct.chart"
            [xaxis]="areaChartOptionsTopProduct.xaxis"
            [dataLabels]="areaChartOptionsTopProduct.dataLabels"
            [stroke]="areaChartOptionsTopProduct.stroke"
            [title]="areaChartOptionsTopProduct.title"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>
