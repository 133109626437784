<app-breadcrumbs
  title="Products"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>
<!-- end row -->

<div
  class="row"
  *ngIf="!hasPermissionForProduct('VIEW_PRODUCT')"
  style="
    text-align: center;
    padding: 10px;
    background-color: #ffebee;
    border: 1px solid #e57373;
    border-radius: 5px;
    color: #e57373;
  "
>
  Access Denied: You do not have permission to continue.
</div>

<div class="row" *ngIf="hasPermissionForProduct('VIEW_PRODUCT')">
  <div class="col-12">
    <div class="card">
      <div
        class="card-header custom-tr"
        *ngIf="hasPermissionForProduct('CREATE_PRODUCT')"
      >
        <div class="row">
          <span class="col-lg-3">
            <label for="basiInput" class="form-label"
              >Search by product name
            </label>
            <input
              type="text"
              placeholder="Product Name"
              class="form-control"
              id="basiInput"
              [(ngModel)]="searchValue"
              (ngModelChange)="searchOnChange($event)"
            />
          </span>

          <span class="col-lg-3">
            <label for="basiInput" class="form-label"
              >Search by category name
            </label>
            <input
              type="text"
              placeholder="Category Name"
              class="form-control"
              id="basiInput_category"
              [(ngModel)]="searchValueCategory"
              (ngModelChange)="searchOnChangeCategory($event)"
            />
          </span>

          <span class="col-lg-3">
            <label for="filter" class="form-label">Products status </label>
            <ng-select
              [clearable]="false"
              placeholder="Select status"
              id="filter"
              [items]="activeStatus"
              bindLabel="name"
              class="my-dropdown"
              [(ngModel)]="anotherSelect"
              (change)="changeStatus($event)"
            >
              <ng-template ng-optgroup-tmp let-item="item">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </span>

          <div class="col-lg-3 btn_area">
            <a
              [routerLink]="['add']"
              class="btn btn-primary waves-effect waves-light"
            >
              Add New Product &nbsp;<strong>+</strong>
            </a>
          </div>
        </div>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-gridjs">
            <thead>
              <tr>
                <th>Name</th>
                <th>Category</th>
                <th>Status</th>
                <th class="custom-tr">Price (LKR)</th>
                <th class="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              @for (item of products; track item; let i = $index) {
              <tr>
                <td>{{ item.name | capitalize }}</td>
                <td>{{ item.category.name | capitalize }}</td>
                <td>
                  @if(item.status == 1) {
                  <span class="badge bg-success-subtle text-success"
                    >Active</span
                  >
                  } @else {
                  <span class="badge bg-warning-subtle text-warning"
                    >Deactive</span
                  >
                  }
                </td>
                <td class="custom-tr">{{ item.sellingPrice }}</td>
                <td class="text-end">
                  <button
                    *ngIf="hasPermissionForProduct('UPDATE_PRODUCT')"
                    type="button"
                    class="btn btn-sm btn-primary custom-mr-5 waves-effect waves-light"
                    [routerLink]="['/product/edit', item.id]"
                  >
                    Edit
                  </button>
                  <button
                    *ngIf="hasPermissionForProduct('DELETE_PRODUCT')"
                    type="button"
                    class="pl-5 btn btn-sm btn-danger waves-effect waves-light"
                    (click)="deleteProduct(item.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
        <div class="row justify-content-md-between align-items-md-center">
          <div class="col-sm-12 col-md-5">
            <div
              class="dataTables_info mb-2"
              id="tickets-table_info"
              role="status"
              aria-live="polite"
            >
              {{ calculateEntryRange() }}
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end gridjs-pagination">
              <ngb-pagination
                (pageChange)="loadPage($event)"
                [collectionSize]="totalCount"
                [page]="currentPage"
                [pageSize]="pageSize"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->
<ngx-ui-loader></ngx-ui-loader>
