import { Injectable } from '@angular/core';
import { getFirebaseBackend } from '../../authUtils';
import { User } from 'src/app/store/Authentication/auth.models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { GlobalComponent } from "../../global-component";
import { Store } from '@ngrx/store';
import { RegisterSuccess, loginFailure, loginSuccess, logout, logoutSuccess } from 'src/app/store/Authentication/authentication.actions';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/api-response.model';
import { AuthRoutes } from 'src/app/routes/auth-routes';
import { TokenStorageService } from './token-storage.service';
import { jwtDecode } from 'jwt-decode';
const loginRoute = AuthRoutes.loginApi;
const refreshTokenRoute = AuthRoutes.refreshTokenApi;

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {

    user!: User;
    currentUserValue: any;

    private currentUserSubject: BehaviorSubject<User>;
    // public currentUser: Observable<User>;

    constructor(private http: HttpClient, private store: Store, private tokenStorageService: TokenStorageService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
    }

    public $refreshToken = new Subject<boolean>;
    public $refreshTokenReceived = new Subject<boolean>;

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, first_name: string, password: string) {
        // return getFirebaseBackend()!.registerUser(email, password).then((response: any) => {
        //     const user = response;
        //     return user;
        // });

        // Register Api
        return this.http.post(loginRoute + 'signup', {
            email,
            first_name,
            password,
        }, httpOptions).pipe(
            map((response: any) => {
                const user = response;
                return user;
            }),
            catchError((error: any) => {
                const errorMessage = 'Login failed'; // Customize the error message as needed
                this.store.dispatch(loginFailure({ error: errorMessage }));
                return throwError(errorMessage);
            })
        );
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(data: any): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(loginRoute, data);
    }

    isTokenExpired() {
        const token = this.tokenStorageService.getToken();
        if (!token) return true;
        const decoded = jwtDecode(token);
        if (!decoded.exp) return true;
        const expirationDate = decoded.exp * 1000;
        const now = new Date().getTime();
        console.log("yesssss", expirationDate < now);

        return expirationDate < now;
    }

    refreshToken() {
        return this.http.post<any>(refreshTokenRoute, null);
    }


    /**
     * Returns the current user
     */
    public currentUser(): any {
        return JSON.parse(localStorage.getItem('currentUser')!);
    }

    saveResponse(response: any) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
    }

    /**
     * Logout the user
     */

    logout() {
        this.store.dispatch(logout());
        // logout the user
        // return getFirebaseBackend()!.logout();
        localStorage.removeItem('toast');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        this.currentUserSubject.next(null!);

        return of(undefined).pipe(

        );

    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend()!.forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    public getUserAccessToken(): any {
        return localStorage.getItem('access-token');
    }

}

