import { environment } from "src/environments/environment";


const apiURL = environment.apiURL;

export const OrderRoutes = {

    // order
    getAllOrdersURL : `${apiURL}/admin/orders/find-all-orders`,
    orderDetailsURL : `${apiURL}/admin/orders/find-one-order`,
    orderUpdateURL : `${apiURL}/admin/orders`,
    changeStatusURL : `${apiURL}/admin/orders/status-update`
}